export const COMPANY_LOGIN_REQUEST = 'COMPANY_LOGIN_REQUEST';
export const COMPANY_FORGET_PASSWORD_REQUEST  = 'COMPANY_FORGET_PASSWORD_REQUEST';
export const SOCIAL_LOGIN_REQUEST = 'SOCIAL_LOGIN_REQUEST';
export const SOCIAL_LOGIN_PREREQUESTION_MODAL_REQUEST = "SOCIAL_LOGIN_PREREQUESTION_MODAL_REQUEST";
export const SOCIAL_LOGIN_PREREQUESTION_FILLED_REQUEST = "SOCIAL_LOGIN_PREREQUESTION_FILLED_REQUEST";
export const SOCIAL_LOGIN_POPUP_OPENED_REQUEST = "SOCIAL_LOGIN_POPUP_OPENED_REQUEST";
export const SOCIAL_LOGIN_PHONE_NUMBER_REQUIRED = "SOCIAL_LOGIN_PHONE_NUMBER_REQUIRED";
export const REQUEST_COMPANY_SOCIAL_LOGIN = "REQUEST_COMPANY_SOCIAL_LOGIN";
export const REDIRECT_COMPANY_TO_VALIDATE_DOMAIN_REQUEST = "REDIRECT_COMPANY_TO_VALIDATE_DOMAIN_REQUEST"
export const REDIRECT_COMPANY_TO_LOGIN_REQUEST = "REDIRECT_COMPANY_TO_LOGIN_REQUEST";
export const GET_COMPANY_PORTAL_SETUP_REQUEST = 'GET_COMPANY_PORTAL_SETUP_REQUEST';
export const GET_COMPANY_PORTAL_SETUP_RECIEVE = 'GET_COMPANY_PORTAL_SETUP_RECIEVE';

export const CREATE_NEW_BLOG_REQUEST="CREATE_NEW_BLOG_REQUEST"


export const GET_BLOGS_LIST_REQUEST="GET_BLOGS_LIST_REQUEST"
export const GET_BLOGS_LIST_RECIEVE="GET_BLOGS_LIST_RECIEVE"
export const GET_LATEST_BLOG_REQUEST="GET_LATEST_BLOG_REQUEST"
export const GET_LATEST_BLOG_RECIEVE="GET_LATEST_BLOG_RECIEVE"

export const GET_BLOGS_LIST_WEBSITE_REQUEST="GET_BLOGS_LIST_WEBSITE_REQUEST"
export const GET_BLOGS_LIST_WEBSITE_RECIEVE="GET_BLOGS_LIST_WEBSITE_RECIEVE"

export const GET_BLOG_SINGLE_REQUEST="GET_BLOG_SINGLE_REQUEST"
export const GET_BLOG_SINGLE_RECIEVE="GET_BLOG_SINGLE_RECIEVE"


export const GET_BLOG_SINGLE_WEBSITE_REQUEST="GET_BLOG_SINGLE_WEBSITE_REQUEST"
export const GET_BLOG_SINGLE_WEBSITE_RECIEVE="GET_BLOG_SINGLE_WEBSITE_RECIEVE"

export const DELETE_BLOG_REQUEST="DELETE_BLOG_REQUEST"

export const UPDATE_BLOG_REQUEST="UPDATE_BLOG_REQUEST"

export  const CLEAR_BLOG_REQUEST="CLEAR_BLOG_REQUEST"
export  const CLEAR_BLOG_RECIEVE="CLEAR_BLOG_RECIEVE"


