export const DASHBOARD_SEND_EMAIL_NOTIFICATIONS_REQUEST = "DASHBOARD_SEND_EMAIL_NOTIFICATIONS_REQUEST";
export const DASHBOARD_SEND_EMAIL_NOTIFICATIONS_RECIEVE = "DASHBOARD_SEND_EMAIL_NOTIFICATIONS_RECIEVE";
export const SET_SEND_EMAIL_NOTIFICATIONS_REQUEST="SET_SEND_EMAIL_NOTIFICATIONS_REQUEST"
export const DUPLICATE_EMAIL_NOTIFICATIONS_REQUEST="DUPLICATE_EMAIL_NOTIFICATIONS_REQUEST"
export const DUPLICATE_EMAIL_NOTIFICATIONS_RECIEVE="DUPLICATE_EMAIL_NOTIFICATIONS_RECIEVE"
export const DELETE_SEND_EMAIL_NOTIFICATIONS_REQUEST="DELETE_SEND_EMAIL_NOTIFICATIONS_REQUEST"
export const GET_DASHBOARD_MESSAGES_REQUEST = "GET_DASHBOARD_MESSAGES_REQUEST";
export const GET_DASHBOARD_MESSAGES_RECIEVE = "GET_DASHBOARD_MESSAGES_RECIEVE";
export const CLEAR_DASHBOARD_MESSAGES_REQUEST="CLEAR_DASHBOARD_MESSAGES_REQUEST"
export const CLEAR_DASHBOARD_MESSAGES_RECIEVE="CLEAR_DASHBOARD_MESSAGES_RECIEVE"

