import { combineReducers } from "redux";
import sideMenu from "./SideMenu";
import activePageIndex from "./ClickedPageIndex";
import registeredCompanies from "./registeredCompanies";
import candidateSetup from "./candidateSetup";
import dashboard from "./dashboard";
import promoCodes from "./promoCodes";
import planCustomization from './subscriptions'
import jobDescription from "./jobDescription";
import sendEmailNontifications from "./sendEmailNontifications"
import emailTemplate from "./emailTemplate"
export default combineReducers({
  sideMenu,
  activePageIndex,
  registeredCompanies,
  candidateSetup,
  dashboard,
  promoCodes,
  planCustomization,
  jobDescription,
  sendEmailNontifications,
  emailTemplate
});
