export default {
  ar: {
    home: "الصفحة الرئيسية",
    langBtn: "English",
    products: "الخدمات",
    aboutUs: "عن الشركة",
    contactUs: "تواصل معنا",
    pricing: "أنظمة الاشتراك",
    fqa: "الأسئلة الشائعة",
    jobSeekerTitle: "باحث عن عمل",
    signIn: "تسجيل الدخول",
    signUp: "سجّل شركتك",
    registerCompany: "سجّل شركتك",
    tryFree: "تجربة مجانية",
    subscribe: "اشترك",
    userName: "اسم المشترك",
    paymentMethods: [
      "اختــــر ما يناسبــــك من طـــــرق ",
      "الـــدفع المــتنوعـــة",
    ],
    email: "البريد الاكترونى",
    subscribe_email: "البريد الإلكتروني",
    subscribe_header: "اشتراك",
    contactModalSuccess: "شكراً على رسالتك سوف نقوم بالرد قريباً",
    subscribedModalSuccess: "لقد قمت بالاشتراك بنجاح",
    subscribe_sub_header:
      "اشترك معنا وانضم لقائمتنا البريديّة حتى تصلك آخر عروضنا الخاصة، ولتكن على اطّلاع دائم بكل ما هو جديد في عالم التوظيف والموارد البشرية.",
    reviews_sub_header: "رأى عملائنا يهمنا",
    privacyPolicy: "سياسة الخصوصية",
    termsAndConditions: "الأحكام والشروط",
    disclaimer: "توضيحات هامة",
    signUpAgreeMsg: "بتسجيل شركتك معنا ، فإنك توافق على ",
    serviceSection: {
      WhatDoYouGet: "ماذا نقدم لك ؟",
      excitedToKnow: "متحمس لمعرفة ما يقدمه نظامنا؟",
      ourFeatures: "مميزات عديدة لا تصدق!",
      manpowerRequest: {
        name: "طلب وظيفة",
        description: "فتح شاغر وظيفي جديد بأدق التفاصيل.",
      },
      CloudArchive: {
        name: "التخزين السحابي",
        description: "تخزين بياناتك وملفاتك بأمان في نظامنا الرقمي.",
      },
      jobAdvertising: {
        name: " إعلانات الوظائف",
        description:
          "عرض مميز للوظائف الشاغرة على مواقع التواصل الاجتماعي وبناء تفاعل مستمر مع المرشحين. ",
      },
      hiringPipeline: {
        name: "مراحل التوظيف",
        description:
          " تنظيم كافة مراحل عميلة التوظيف بالطريقة المناسبة لمنظمتك. ",
      },
      cvParsing: {
        name: "تحليل السير الذاتية",
        description:
          "الحصول على بيانات منظمة عن طريق تحليل مئات السير الذاتية في أسرع وقت.",
      },
      multipleIntegrations: {
        name: "الدمج مع التقنيات",
        description:
          " الربط والتزامن مع مختلف الأدوات والتقنيات لتحقيق نتائج فعالة.",
      },
      teamCollaboration: {
        name: "التعاون بين الفريق",
        description:
          "تعيين صلاحيات مختلفة لكل عضو في فريق العمل حسب المهام المطلوبة. ",
      },
      videoInterviewing: {
        name: "مقابلات الفيديو",
        description: "تحديد مقابلات مرئية مع الباحثين عن العمل.",
      },
    },
    paginat: {
      total: "الاجمالي",
    },
    socialLogin: {
      sectionTitle: "أو بواسطة",
      googleBtn: "Google",
      linkedinBtn: "Linkedin",
      microsoftBtn: "Microsoft",
      prerequestionTitle: "البيانات الأساسية",
    },
    errors: {
      validPhone: "رقم الهاتف غير صحيح",
      required: "هذا الحقل مطلوب",
      validDate: "التاريخ غير صحيح",
      validEmail: "بريد الكترونى غير صحيح",
      validDomain:
        "مسموح للغة الإنجليزية فقط ، ولا يُسمح بالتباعد والحروف الخاصة",
      validPassoword_title_tip: "يجب أن تتبع كلمة المرور القوية هذه القواعد :",
      validPassord_min_tip: "على الأقل 8 أحرف ",
      validPassword_mix: "مزيج من الأحرف الكبيرة والصغيرة",
      validPassword_letters: "مزيج من الحروف والأرقام",
      validPassword:
        " رمز مرور خاطئ الاحرف على الاقل 8 و مزيج من الاحرف الكبيرة و الصغيرة و الارقام و الرموز الخاصة",
      validMatching: "كلمة المرور وتأكيد كلمة المرور لا تتطابق",
      invalidPassword:
        " رمز مرور خاطئ الاحرف على الاقل 8 و مزيج من الاحرف الكبيرة و الصغيرة و الارقام",
      confirmPasswordMatch: "كلمة المرور وتأكيد كلمة المرور لا تتطابق",
      imageMaxSize: "يجب ان لا يتعدى حجم الصورة 10 ميجا بايت",
      imagetype: "JPEG, Png فقط مسموح ",
      CVfileType: "مسموح فقط PDF, Word",
      fileMaxSize: "يجب ان لا يتعدى حجم الملف 3 ميجا بايت",
      invalidGPAValue: "يجب ان يكون الرقم مابين 0 - ",
      toGreaterThanFrom: "يجب ان تكون 'الى' اكبر 'من' ",
      promoCodeDecimals: "يقبل فقط 4 ارقام صحيحة ويسمح كحد أقصى برقمين عشريين",
      maxPercentenge: "الحد الاقصى 100",
      companyNameMessage: "الحقل يجب أن يحتوي حروف وأرقام فقط",
      maxLength50: "الحد الاقصي 50",
      maxLength5000: "الحد الاقصي 5000",

      maxLength200: "الحد الاقصي 200",

      maxLength5_number:
        "الحقل يجب أن يحتوي أرقام فقط والحد الأقصي 5 ولا يبدأ ب0",
      maxLength4_number:
        "الحقل يجب أن يحتوي أرقام فقط والحد الأقصي 4 ولا يبدأ ب0",
      invalidWebsiteUrl:
        "برجاء إدخال عنوان صالح للموقع (مثال: https://hrcom.io)",
      choosePath: "اختر وجهتك",
    },
    products_cards: {
      product1:
        "كن أقرب للمواهب والكفاءات وأسرع وأدق في عملية التوظيف وإدارة الموارد البشرية رقميّاً من خلال أحدث الحلول التقنية التي ستمكّنك من تحقيق أهداف منشأتك.",
      product2:
        "مكّن حديثي الانضمام من فهم ثقافة المنشأة ورؤيتها والقيم التي تتبناها وتعمل على أساسها، وعرفّهم أكثر على أهميّة دورهم في تحقيق أهدافك الإستراتيجية.",
    },
    about_us_content: {
      about_us_title: "تعرف علينا",
      header1: "نسعى دائمًا لخلق تواصل قوي ",
      header2: "في مجال التوظيف!",
      description:
        "لأننا نؤمن بأهمية التحول الرقمي وتأثيره في الوصول إلى أهداف شركتك، فقد قمنا بتطوير نظام تعيين المهارات الذي يمكّنك منه إعداد عملية توظيف ناجحة وتحقيق أهدافك بدقة وفعالية أكبر.",
      sub_content_header: "التمكين شعار نعمل به!",
      sub_content_decription:
        "إيماناً منا بأهميّة التحول الرقمي وتأكيداً على دوره في تحقيق أهداف منشأتك الاستراتيجية، قمنا في اتش ار كوم بتصميم نظام إلكتروني شامل لكافة عمليات وحلول الموارد البشرية لنمكنك من التصويب نحو أهدافك بكامل التركيز والدقة. ",
      mission_content:
        "تسهيل عملية التوظيف من خلال ربط الشركات بأفضل المواهب حول العالم من خلال نظام ذكي لتتبع المهارات.",
      vission_content:
        "تحويل عملية التوظيف في الشرق الأوسط من تقليدية إلى تقنية.",
      Values_content: ["الجودة", "الكفاءة", "الشغف", "الاحترافية"],
      mission: "الهدف",
      vission: "الرؤية",
      Values: "القيم",
      storyTitle: "هويتنا نحكيها بقصة!",
      us_in_details: "تفاصيل عننا",
      us_in_details_content:
        "تقدم شركتنا أفضل الحلول التقنية لإجراءات توظيف المهارات. مهما كان حجم شركتك، ستجد الجودة والنتائج التي تطمح لها.",
    },
    features_content: {
      title1: "لماذا إتش أر كوم؟",
      title2: "نظام متكامل يناسب شركتك!",
      content:
        "نعلم أن زيادة إنتاجية الموارد البشرية ستؤثر في  أداء شركتك بشكل إيجابي وفعال ،لذا حرصنا على إعداد أداتنا بهذه المزايا للدفع بمخططاتك إلى الأمام.",
      feature_1_title: "خبــراء توظيف",
      feature_1_content:
        "نقدر وقتك وجهودك لهذا السبب نوفر لك أداة كاملة لدعمك في استراتيجية إدارة الموارد البشرية لديك",
      feature_2_title: "تطور تكنولوجي",
      feature_2_content:
        "وصل وظائفك الشاغرة للمرشحين المناسبين! نشر فرص العمل الخاصة بك مع تحديد المسمى الوظيفي ومتطلبات العمل بدق.",
      feature_3_title: "سهولة الاستخدام",
      feature_3_content:
        "نظير يمكنك الاعتماد علينا في كل مرحلة من مراحل عملية التوظيف التي تؤدي إلى اتخاذ القرارات الخاصة بك ، وتعزيز استراتيجيات النمو الخاصة بك في كل خطوة.",
      feature_4_title: "أسعــار مناسبة",
      feature_4_content:
        "إمكانية تنظيم وتخزين والوصول إلى البيانات الخاصة بقسم الموارد البشرية على الفور ، واكتشاف أنماط ذات مغزى من شأنها أن تدعم هيكل الموارد البشرية.",
      fast: "سريع",
      accountable: "معروف",
      efficient: "كفء",
      targeted: "محدد",
      feature_5_title: "تكوين شبكة علاقات",
      feature_6_title: "دعم فني مستمر",
      feature_7_title: "شبكـــات آمنة",
      feature_8_title: "أنظمة مخصصة",
      feature_9_title: "قاعدة المتقدمين",
    },
    contact_us_content: {
      contact_us_title: "أعرف أكثر عن النظام هنا!",
      contact_us_content:
        "لمزيد من المعلومات، املأ البيانات التالية وسنقوم بالتواصل معك والرد عليك",
      gladTo: "فخـورون بـوجــودك",
      here: "هــنا !",
      getInTouch: "تواصــل معنــا ,",
      happyToHelp: "وسنكون سعداء بمساعدتك في أي وقت!",
      companies_questions: "أسئلة الشركات",
      candidates_questions: "أسئلة المرشحين",
      companies: "للــشركات",
      candidates: "للــمرشحين",
    },
    faqs: {
      clients: {
        faq1: {
          question: "ما هو نظام HRCOM ؟",
          answer: "نظام تتبع للمتقدمين يسهل عليك عملية التوظيف بأكملها.",
        },
        faq2: {
          question: "هل يمكن استخراج البيانات؟",
          answer: "بالطبع يمكنك استخراجها من خلال طلب الحصول على البيانات.",
        },
        faq3: {
          question: "هل يتم حفظ بيانات الحساب المجاني إذا قمت بتفعيل الشراء؟",
          answer: "يتم الاحتفاظ بالإعدادات والبيانات لك دائماً.",
        },
        faq4: {
          question: "هل من السهل التحويل من نظام توظيف آخر إلى عالمنا؟",
          answer: "بالطبع، سيسهل عليك فريقنا التقني جميع خطوات الدمج والتدريب.",
        },
        faq5: {
          question: "مَن سيستفيد مِن استخدام نظامنا؟",
          answer:
            "أي شركة ترغب في تحسين عملية التوظيف لديها وجذب أكبر المواهب في العالم.",
        },
        faq6: {
          question: "هل يوجد أي مصاريف للحساب؟",
          answer: "لا يوجد، فقط رسوم الاشتراك.",
        },
        faq7: {
          question: "هل هناك رسوم على إلغاء الاشتراك؟",
          answer: "لا شئ على الإطلاق.",
        },
      },
      candidates: {
        faq1: {
          question: "أين تحفظ بياناتي في النظام؟",
          answer:
            "يتم حفظ البيانات واستخدامها عن طريق الشركات المتواجدة داخل النظام لإرسال عروض العمل بسهولة إليك.",
        },
        faq2: {
          question: "هل مقابلات العمل تتم من خلالكم أم من خلال الشركة؟ ",
          answer: "الشركات المسجلة لدينا تقوم بكافة عملية التوظيف.",
        },
        faq3: {
          question: "كيف يمكن الاستفادة من نظام إتش أر كوم؟",
          answer:
            "يمكنك إنشاء ملف خاص بك والتقديم على الوظائف من خلاله، أو التقدم لوظيفة مباشرة بشكل أسهل.",
        },
      },
    },
    shared: {
      send_again: "ارسل مرة اخرى",
      address: "العنوان",
      submit: "ارسال",
      message: "الرسالة",
      joinUs: "انضم الينا",
      message_placeholder: "اكتب رسالتك هنا",
      register: "تسجيل",
      add_password_title: "اضف كلمة المرور",
      registeryourCompany: "سجل ",
      yourCompany: "شركتك ",
      company:'شركة',
      requestDemo: "فلــنبدأ",
      scheduleDemo: ["طلب ", " عرض توضيحي"],
      lets_start: " سجل الآن!",
      fill_all_form: "*برجاء ملئ جميع الحقول",
      success_register:
        " تم إرسال بريد إلكتروني للتأكيد ، يرجى التحقق من بريدك الإلكتروني.  إذا لم تتلق رسالة التأكيد الإلكترونية في غضون دقيقة واحدة ، فيرجى الضغط على إرسال مرة أخرى",
      notFoundRecords: "لا توجد بيانات",
      name: "الاسم",
      email: "البريد الإلكتروني",
      phone: "الهاتف",
      NoDataFound: "لا يوجد بيانات",
      and: "و",
      signUpNote: "خاص بالشركات",
    },
    viewAllRequests: {
      requestId: "كود الطلب",
      jobTitles: "المسمى الوظيفي",
      vacanciesNumber: "عدد الوظائف الشاغرة",
      joiningDate: "تاريخ الالتحاق",
      state: "حالة الطلب",
      creationDate: "تاريخ الموافقة",
      jobRequistion: "متطلبات الوظيفة",
      summary: "موجز",
      screening: "التحري",
      tabsInprogress: "قيد التنفيذ",
      tabsPublished: "منشور",
      tabsClosed: "مغلق",
      by: "بواسطة",
      deleteRequestModalHeader: "حذف الطلب",
      deleteRequestModalContent: "هل ترغب فى حذف هذا الطلب؟",
      publishingDate: "تاريخ النشر",
      closingDate: "تاريخ الانتهاء",
      branch: "الفرع",
      country: "البلد",
      city: "المدينة",
      status: "الحالة",
      totalApplicants: "مجموع المتقدمين",
      applied: "المتقدمين الجدد",
      shortListed: "shortListed",
      offers: "العقود",
      accepted: "المقبولين",
      interviews: "المقابلات (مكتملة / مجدولة)",
      rejected: "مرفوضة",
      manageCandidates: "إدارة المتقدمين",
      delayClosingDate: "تأخير ميعاد الغلق",
      outOf: "من أصل",
      waitingForApproval: "بانتظار الموافقة",
      approvedRequest: "مصدق عليه",
      disapprovedRequest: "مرفوض",
    },
    viewAllRequests: {
      requestId: "كود الطلب",
      jobTitles: "المسمى الوظيفي",
      vacanciesNumber: "عدد الوظائف الشاغرة",
      joiningDate: "تاريخ الالتحاق",
      state: "حالة الطلب",
      creationDate: "تاريخ الموافقة",
      jobRequistion: "متطلبات الوظيفة",
      summary: "موجز",
      screening: "التحري",
      tabsInprogress: "قيد التنفيذ",
      tabsPublished: "منشور",
      tabsClosed: "مغلق",
      by: "بواسطة",
      deleteRequestModalHeader: "حذف الطلب",
      deleteRequestModalContent: "هل ترغب فى حذف هذا الطلب؟",
      publishingDate: "تاريخ النشر",
      closingDate: "تاريخ الانتهاء",
      branch: "الفرع",
      country: "البلد",
      city: "المدينة",
      status: "الحالة",
      totalApplicants: "مجموع المتقدمين",
      applied: "المتقدمين الجدد",
      shortListed: "shortListed",
      offers: "العقود",
      accepted: "المقبولين",
      interviews: "المقابلات (مكتملة / مجدولة)",
      rejected: "مرفوضة",
      manageCandidates: "إدارة المتقدمين",
      delayClosingDate: "تأخير ميعاد الغلق",
      outOf: "من أصل",
      waitingForApproval: "بانتظار الموافقة",
      approvedRequest: "مصدق عليه",
      disapprovedRequest: "مرفوض",
    },

    buttons: {
      addField: "إضافة حقل",

      attachFile: "ارفق ملف",
      activate: "مفعل",
      viewInvoices: "عرض الفاتورة",
      tax: "فاتورة ضريبية",
      print: "طباعة",
      download: "تحميل",
      deactivate: "موقوف",
      cancel: "الغاء",
      submit: "ارسال",
      Update: "تعديل",
      yes: "نعم",
      no: "لا",
      filter: "تصفية",
      applyFilter: "طبق",
      resetSearch: "مسح الكل",
      notFoundRecords: "لا توجد بيانات",
      reset: "استعادة كلمة المرور",
      save: "حفظ",
      moreDetails: "بيانات الشركة",
      send: "ارسل",
      uploadCv: "أرفع سيرتك الذاتيه",
      addSection: "أضف قسم",
      bussinessOwner: "صاحب العمل",
      jobSeeker: "باحث عن عمل",
      edit: "تعديل",
      delete: "مسح",
      saveSend: "حفظ و ارسال",
      done: "تم",
      reqestDemo: "طلب عرض توضيحي",
      requestDemoBanner: "طلب عرض توضيحي",
    },
    form_controls: {
      firstName: "الاسم الاول",
      lastName: "اسم العائلة",
      fullName: "الاسم",
      dateOfBirth: "تاريخ الميلاد",
      residency: "الاقامة",
      gender: "النوع",
      maritalStatus: "الحالة الاجتماعية",
      phoneNumber: "رقم الجوال",
      personalEmail: "البريد الالكترونى",
      address: "العنوان",
      skillsInfoTitle: "المهارات",
      profLevel: "مستوى الكفاءة",
      languages: "اللغات",
      college: "الكلية",
      degree: "الدرجة العلمية",
      businessEmail: "البريد الإلكتروني الخاص بالعمل",
      gpa: "GPA",
      category: "تصنيف",
      jobTitle: "اسم الوظيفة",
      companyName: "اسم الشركة",
      location: "الموقع",
      from: "من",
      to: "إلى",
      description: "الوصف",
      addEducation: "إضافة المؤهل",
      editEducation: "تعديل المؤهل",
      toPresent: "إلى الوقت الحالى",
      addWorkExperience: "إضافة الخبرة",
      editWorkExperience: "تعديل الخبرة",
      present: "الوقت الحالى",
      outOf: "من أصل",
      customJobTitle: "ادخل اسم الوظيفة",
      first_name: "الاسم الاول",
      last_name: "اسم العائلة",
      email: "البريد الالكترونى",
      company_name_ar: "اسم الشركة بالعربية",
      company_name_en: "اسم الشركة بالانجليزية",
      domain: "النطاق",
      password: "كلمة المرور",
      confirm_password: "تاكيد كلمة المرور",
      hearAboutUs: "كيف سمعت عنا؟",
      websiteUrl: "رابط الشركة",
      comment: "نقاط تود مناقشتها",
      recuitBefore: "هل سبق واستخدمت نظام توظيف رقمي؟",
      yes: "نعم",
      no: "لا",
      systemName: "ما هو النظام؟",
    },
    products_content: {
      recruitment: "التعيين",
      onboard: "التأهيل",
      rightPlace: "وصلت للمكان الصحيح!",
      title1: "الوظائف المتاحة",
      content1:
        "أعرض كل حالة وظيفية بوضوح وقم بتحليل كل وظيفة شاغرة في صفحة واحدة.",
      title2: "قاعدة المتقدمين",
      content2:
        "قم بالوصول إلى قائمة المرشحين المحتملين لديك، حدد المؤهلين للوظيفة، وقم بإرسال عرض جديد.",
      title3: "إدارة المقابلات",
      content3:
        "نظم جميع التفاصيل المتعلقة بمقابلات المرشحين مع ربط التقييمات لكل مقابلة.",
      title4: "نموذج العقد",
      content4: "أنشئ قالب جاهز للعقد الوظيفي بكامل تفاصيله.",
    },
    footer: {
      address:
        "شارع العليا العام - طريق النور 12222 الرياض، المملكة العربية السعودية",
      ourProducts: "خدماتنا",
      startFreeTrial: "ابدأ تجربتك المجانية",
      postFreeJob: 'أعلن عن وظيفة مجانا"',
      searchForJobs: "البحث عن وظيفة",
    },
    auth: {
      email: "البريد الالكترونى",
      forgetPassword: "نسيت كلمة المرور ؟",
      forgetPasswordTitle: "نسيت كلمة المرور",
      password: "كلمة المرور",
      confirmPassword: "تاكيد كلمة المرور",
      passwordValidRules: "يجب أن تتبع كلمة المرور القوية هذه القواعد:",
      passwordValidTipMin: "على الأقل 8 أحرف",
      passwordValidTipUpperLower: "مزيج من الأحرف الكبيرة والصغيرة",
      passwordValidTipLetterNumber: "مزيج من الحروف والأرقام",
      ForgetPasswordHeading: "نسيت كلمة المرور",
      Reset: "استعادة كلمة المرور",
      changePassword: "تغيير كلمة المرور",
      currentPassword: "كلمة المرور الحالية",
      newPassword: "كلمة مرور جديدة",
      logout: "الخروج",
    },
    snackbar: {
      ChangesAppliedSuccessfully: "تم حفظ التغيرات بنجاح",
      done: "تم بنجاح",
      NotificationHasbeenDeleted: "تم حذف المسوده",
      savedSuccessfuly: "لقد تم حفظ البيانات بنجاح",
      delayClosingDate: "لقد تم التحديث بنجاح",
      contactModalSuccess: "شكرًا على اتصالك بنا ، لقد تم إرسال رسالتك بنجاح",
      moveToInterviewGrid: "لقد تم النقل الى المقابلات بنجاح",
      successMoveShortlisted: "تم نقل المرشح إلى القائمة المختصرة بنجاح",
      rejectedSuccessfully: "لقد تم رفض الطلب بنجاح",
      emailSentSuccessfully: "لقد تم ارسال البريد بنجاح",
      usedPlan: " لا يمكن حذف هذه الخطة انها مستخدمة بواسطة",
    },
    report: {
      Offers: "العروض",
      totalOffers: " مجموع العروض",
      Accepted: "مقبول",
      Rejected: "مرفوض",
      Negotiation: "تفاوض",
      "Waiting for candidate": "في انتظار المتقدم",
      Pending: "قيد الإنتظار",
      Interviews: "المقابلات",
      website: "بواسطة الموقع",
      manual: "مضاف يدوياً",
      totalInterviews: "مجموع المقابلات",
      "Face-to-face": "وجهًا لوجه",
      Phone: "هاتف",
      Video: "فيديو",
      addedByWebsite: "متقدم عن طريق الموقع",
      addedManual: "مضاف يدوياً",
      totalCandidates: "مجموع المتقدمين",
      totalJobOpening: "مجموع الوظائف المتاحة",
      jobOpening: "الوظائف المتاحة",
      "In progress": "قيد التنفيذ",
      Opened: "مفتوحة",
      hold: "معلق",
      closed: "مغلقة",
      Agents: "المستخدمين",
      "Total of agents": "مجموع المستخدمين",
      Activated: "مفعل",
      Deactivated: "غير مفعل",
      Exportfile: "استخراج ملف",
    },
    contract: {
      Contracts: "العقود",
      totalContracts: "مجموع العقود",
      Accepted: "مقبول",
      Rejected: "مرفوض",
      Negotiation: "تفاوض",
      "Waiting for candidate": "في انتظار المتقدم",
      Pending: "قيد الإنتظار",
      Interviews: "المقابلات",
      website: "بواسطة الموقع",
      manual: "مضاف يدوياً",
      totalInterviews: "مجموع المقابلات",
      "Face-to-face": "وجهًا لوجه",
      Phone: "هاتف",
      Video: "فيديو",
      addedByWebsite: "متقدم عن طريق الموقع",
      addedManual: "مضاف يدوياً",
      totalCandidates: "مجموع المتقدمين",
      totalJobOpening: "مجموع الوظائف المتاحة",
      jobOpening: "الوظائف المتاحة",
      "In progress": "قيد التنفيذ",
      Opened: "مفتوحة",
      hold: "معلق",
      closed: "مغلقة",
      Agents: "المستخدمين",
      "Total of agents": "مجموع المستخدمين",
      Activated: "مفعل",
      Deactivated: "غير مفعل",
    },
    sideMenu: {
      home: "نافذة التحكم",
      registeredCompanies: "الشركات المسجلة",
      companyDetails: "بيانات الشركة",
      changePassword: "تغيير كلمة المرور",
      setup: "الاعدادات",
      candidateSetup: "اعدادات المتقدمين",
      candidates: "قاعدة المتقدمين",
      promoCodes: "الرمز الترويجى",
      planCustomization: "تعريف الخطة",
      addPlanCustomization: "اضافة خطة جديدة",
      report: "التقارير",
      Add: "Add job title",
      jobDescriptions: "الوصف الوظيفي",
    },
    dashboard: {
      unverifiedCompanies: "شركات غير مؤكده",
      activationRate: "معدل التفعيل",
      goGoogle: "تقارير جوجل",
      registeredCompanies: "الشركات المسجلة",
      activeCompanies: "الشركات الفعالة",
      deactiveCompanies: "الشركات الموقوفة",
      unverifiedActiveCompanies: "شركات غير مؤكده وفعالة",
      unverifiedDeactiveCompanies: "شركات غير مؤكده وموقوفة",
      agents: "العملاء",
      totalConsumedMinutes: "الدقائق المستهلكة",
      currentMonthMinutes: "الدقائق المستهلكة لهذا الشهر",
      candidates: "المتقدمين",
      hrComJobSeekers: "المتقدمين فى اتش ار كوم",
      companiesCandidates: "المتقدمين فى الشركات",
      subscriptions: "خطط الاشتراكات",
      active: "فعال",
      package: "الخطه",
      soonExpired: " سينتهي قريباً ",
      total: "مجموع",
      inactive: "منتهي",
    },
    customSubscriptionPlan: {
      title: "أنـشئ خطتـــك الآن!",
      content:
        "قم بإدارة موظفيك، نشر الوظائف الشاغرة، بناء قاعدة بيانات المرشحين، جدولة المقابلات، وغير ذلك الكثير... ",
      customPlanAction: "تواصل معنا",
      trialPlanTitle: "التجربة المجانية",
      trialPlanCotent_part1: "نسخة تجريبية مجانية لمدة 14 يوم",
      trialPlanCotent_part2: "بدون رسوم",
      trialPlanCotent_part3: "لا حاجة لبطاقة ائتمان",
    },
    JobDescription: {
      addjobTitle: "اضافة مسمى وظيفي",
      Addjobdescription: "اضافة وصف وظيفي",
      JobtitleEN: "المسمى الوظيفي بالانجيلزي",
      JobtitleAR: "المسمى الوظيفي بالعربي",
      JobtitleEN2: "اسم الوظيفه بالانجيلزي",
      JobtitleAR2: "اسم الوظيفه بالعربي",
      jobDescriptionEN: "الوصف الوظيفي بالانجليزي",
      jobDescriptionAR: "الوصف الوظيفي بالعربي",
      deletejobDescriptionMessage: "هل ترغب في حذف هذاالوصف الوظيفي؟",
      deleteJob: "حذف الوصف الوظيفي",
      search: "بحث",
    },
    sendEmailNotifications: {
      Title: "العنوان",
      CreationDate: "تاريخ الإنشاء",
      SentDatetime: "تاريخ الإرسال",
      Status: "الحاله",
      View: "عرض",
      Duplicate: "نسخ",
      SendEmailsNotifications: "إرسال تنبيهات",
      AddNew: "إضافة جديد",
      DeleteDraft: "مسح المسوده",
      deleteDraftMessage: "هل ترغب في حذف هذه المسوده؟",
      Subject: "العنوان",
      EmailBody: "محتوي الإيميل",
      NotificationText: "رسالة التنبيه",
      SendBy: "يرسل بواسطة",
      SaveSend: "حفظ و أرسال",
      SaveAsDraft: "حفظ كمسوده",
      Email: "بريد إلكتروني",
      Notifications: "تنبيهات",
      CompanyStatus: "حالة المستخدم",
      Active: "مفعل",
      NotActive: "غير مفعل",
      SendTo: "يرسل إلي",
      SuperAdmin: "المشرف الرئيسي",
      AllAgents: "كل المستخدمين",
      body: "المحتوي",
      Sent: "تم الإرسال",
      Draft: "مسوده",
      UpdateSend: "حفظ و أرسال",
      UpdateAsDraft: "حفظ",
      warningMsg: "لا يمكن تعديل التنبيهات المرسله",
    },
    planCustomization: {
      planCustomization: "تعريف الخطة",
      addCustomizedPlan: "إضافة خطة",
      planName: "اسم الخطة",
      price: "السعر",
      edit: "تعديل",
      delete: "حذف",
      deletePlan: "حذف الخطة",
      limited: "محدود",
      unlimited: "غير محدود",
      payment_type: "نوع الدفع",
      paymentType: "نوع الدفع *",
      deletePlanMessage: "هل ترغب في حذف هذه الخطة؟",
      generalInfo: "البيانات الأساسية",
      name_ar: "اسم الخطة بالعربية *",
      name_en: "اسم الخطة بالانجليزية *",
      companies: "الشركات",
      annual_price: "السعر سنوياً *",
      month_price: "السعر شهرياً *",
      features: "الخصائص",
      has_send_job_offer: " إرسال عروض توظيف",
      has_screening_questions: "تقييم المتقدمين",
      has_career_questioner: "استبيان توظيفي",
      number_of_users: "عدد المستخدمين / الوكلاء *",
      number_of_jobs: "عدد الوظائف في الشهر *",
      number_of_cvs: " عدد السير الذاتية  *",
      number_of_minutes: "عدد الدقائق لمقابلات الفيديو شهرياً *",
      number_of_workflow_pipelines: "مسار طلبات التوظيف *",
      has_posting_jobs_via_socialmedia: "توظيف عبر وسائل التواصل الإجتماعي",
      has_company_brief: "نبذة عن الشركة",
      has_linking_your_website: "عرض الصفحة الرئيسية الخاصة بالشركة",
      has_brand_colors: "حدد ألوان بوابة المتقدم الخاصة بالشركة ",
      has_brand_logo: " حدد شعارك ببوابة المتقدم الخاصة بالشركة",
      all: "الكل",
      has_pre_screening_assessment: "أسئلة الفرز المسبق للمرشحين",
      has_deticated_success: "مدير حساب خاص للشركة",
      has_send_email: "إرسال بريد إلكتروني",
      has_brand_job_post: "تصميم منشور إعلانات وظائف الشركة",
    },
    registeredCompanies: {
      registeredCompanies: "الشركات المسجلة",
      active: "مفعل",
      inactive: "غير مفعل",
      action: "إجراء",
      mainDetails: "البيانات الأساسية",
      sendEmail: "ارسال بريد إلكتروني",
      verifyEmailHeader: "ارسل مرة أخرى",
      verifyEmailContent:
        "هل تريد تأكيد إرسال بريد إلكتروني للتحقق إلى هذا المستخدم؟",
      companyLogo: "Company logo",
      companyPortal: "بوابة الشركة",
      jobPortal: "بوابة الوظائف",
      companyDomain: "اسم النطاق",
      companyName: "اسم الشركة",
      companySize: "حجم الشركة",
      vatNumber: "الرقم الضريبي",
      companyIndustry: "قطاع الشركة",
      websiteUrl: "رابط موقع الشركة",
      saudiCompany: "شركة سعودية",
      registeredEmail: "البريد الالكترونى المسجل",
      registerationDate: "تاريخ التسجيل",
      trial: "التجربة المجانية",
      basic: "أساسي",
      advanced: "متقدم",
      premium: "متميز",
      notverified: "لم يتم التحقق منها",
      name: "الاسم، النطاق",
      email: "الايميل",
      plan_type: "الخطة",
      noAgents: "عدد العملاء",
      ActivateCompany: "هل ترغب فى تفعيل هذه الشركة",
      DeactivateCompany: "هل ترغب فى ايقاف هذه الشركة ؟",
      ActivateTitle: "تفعيل الشركة",
      DeactivateTitle: "ايقاف الشركة",
      activated: "مفعل",
      deactivated: "موقوف",
      agents: "العملاء",
      jobOpenings: "الوظائف الشاغرة",
      agentName: "اسم العميل",
      agentEmail: "البريد الالكترونى",
      agentPhone: "رقم الجوال",
      agentJobRole: "الوظيفة",
      outOf: "من",
      monthConsumedMinutes: "الدقائق المستهلكة لهذا الشهر",
      totalConsumedMinutes: "مجموع الدقائق المستهلكة",
      totalMonthMinutes: "مجموع الدقائق لهذا الشهر",
      totalMinutes: "مجموع الدقائق",
      plan: "الخطة",
      subscription: "عمليات الدفع",

      acceptTermsAndConditions:
        "بتسجيل شركتك معنا ، فإنك توافق على الأحكام والشروط, سياسة الخصوصية, و إخلاء المسؤولية",
      errorAcceptTermsAndConditions:
        "يجب ان توافق على الشروط و الاحكام وسياسة الخصوصية و  إخلاء المسؤولية",
    },
    login: {
      loginHeading: "تسجيل الدخول",
      forgotPassword: "نسيت كلمة المرور ؟",
      loginTitle: "في نظامنا للتوظيف الرقمي",
      loginSubtitle: "ابدأ رحلتك",
    },
    forgetPassword: {
      forgetPasswordHeading: "نسيت كلمة المرور",
    },
    jobSeeker: {
      engProLevel: "ما هو مستوى إتقانك للغة الإنجليزية؟",
      personal: "المعلومات الشخصية",
      skills: "المهارات",
      education: "التعليم",
      workExperience: "خبرة العمل",
      confirmationDialogContent:
        "تم تسجيل هذا البريد الإلكتروني من قبل، هل تريد تحديث ملفك الشخصي؟",
      reachingGoald: "تحقيق الأهداف يبدأ باتخاذ خطوة نحوها!",
      sub_content: "ابدأ قصة نجاحك وسجّل معنا لتحقيق طموحاتك المهنية.",
    },
    emailTemplates: {
      Actions: "الإجراءات",
      add: "إضافة نموذج",
      templateNameAr: "اسم النموذج بالعربية",
      templateNameEn: "اسم النموذج بالإنجليزية",
      subject: "العنوان",
      body: "المحتوي",
      title: "المقدمة",
      delete: "هل ترغب في حذف هذا النموذج؟",
      deleteHeader: "حذف النموذج",
      maxSizeImage: "*من فضلك ، في حالة تضمين صورة ان لا تتجاوز 2 ميغا بايت",
    },
    blog: {
      NewBlog: "مدونه جديده",
      Image: "الصورة",
      TitleAr: "العنوان باللغه العربيه",
      TitleEn: "العنوان باللغه الإنجليزيه",
      DescriptionAr: "الوصف  باللغه العربيه",
      DescriptionEn: "الوصف باللغه الإنجليزيه",
      ContentAr: "المحتوي باللغه العربيه",
      ContentEn: "المحتوى باللغه الإنجليزيه",
      PublishingDate: "تاريخ النشر",
      Publish: "نشر",
      SaveasDraft: "حفظ كمسوده",
      title: "العنوان",
      seeMore: "اقرأ المزيد",
      deleteBlog: "حذف المدونة",
      deleteBlogmsg: "هل ترغب في حذف هذه المدونة ؟",
      Draft: "مسودة",
      Published: "منشورة",
    },
    settings: {
      generalSettings: "الاعدادات العامة",
      candidateProfile: "اعدادات المتقدمين",
      simpleProfile: "ملف تعريف بسيط",
      complicatedProfile: "الملف التفصيلي",
      promoCode: "الرمز الترويجى",
      JobDescriptions: "الوصف الوظيفي",
      ActionsEmailTemplate: "نماذج البريد الإلكتروني",
      blog: "مدونة",
    },
    candidates: {
      registeredOn: "تاريخ التقديم",
      sendEmail: "ارسال بريد الكترونى",
      personalInfoTitle: "معلومات شخصية",
      firstName: "الاسم الاول",
      lastName: "اسم العائلة",
      dateOfBirth: "تاريخ الميلاد",
      residency: "الاقامة",
      gender: "النوع",
      maritalStatus: "الحالة الاجتماعية",
      contactDetails: "بيانات الاتصال",
      phoneNumber: "رقم الجوال",
      personalEmail: "البريد الالكترونى",
      address: "العنوان",
      male: "ذكر",
      female: "انثى",
      skillsInfoTitle: "المهارات",
      profLevel: "مستوى الكفاءة",
      engProLevel: "ما هو مستوى إتقانك للغة الإنجليزية؟",
      languages: "اللغات",
      profLevelInEnglish: "مستوى إتقانك للغة الإنجليزية ",
      education: "التعليم",
      workExperience: "خبرة العمل",
      college: "الكلية",
      degree: "الدرجة العلمية",
      gpa: "GPA",
      category: "تصنيف",
      jobTitle: "اسم الوظيفة",
      companyName: "اسم الشركة",
      location: "الموقع",
      from: "من",
      to: "إلى",
      description: "الوصف",
      present: "الوقت الحالى",
      subject: "عنوان",
      body: "محتوى",
      advertQuestions: "استطلاع",
      currentLastSalary: "المرتب الحالى",
      currency: "العملة",
      currenctLastPosition: "الوظيفة الحالية",
      currenctLastCompanyName: "الشركة الحالية",
    },
    pricingPage: {
      headerLabel: "أنظمة رائعة. مميزات متنوعة. أسعار مختلفة",
      know_plans: ["تعرف على كل نظام", " عن قرب"],
      freeTrialEndsIn: "التجربة المجانية تنتهى بعد",
      freeTrialEndedIn: "التجربة المجانية انتهت في",
      days: "أيام",
      day: "يوم",
      twoDays: "يومين",
      hours: "ساعات",
      minutes: "دقائق",
      seconds: "ثوانى",
      subscribe: "أشترك الان",
      ends: "التجربة المجانية تنتهي بعد",
      currentPlan: "التجربة الحالية",
      trialPlan: "التجربة المجانية",
      basicPlan: "الخطة الأساسية",
      advancedPlan: "الخطة المتقدمة",
      premiumPlan: "الخطة الممتازة ",
      usersNo: "عدد المستخدمين",
      popularPlan: "الأكثر طلبًا",

      BrandYourPost: "تصميم منشور إعلانات وظائف الشركة",
      DedicatedAccountManager: "مدير حساب خاص للشركة",
      DedicatedSuccess: "استشاري مخصص للحساب",
      CustomizePipelineStage: "تصميم مراحل الوظيفة الخاصة بالشركة	",
      ManageRolesAndPermissions: "إدارة المستخدمين والصلاحيات	",
      ESignatures: "نموذج العرض الوظيفي والتوقيع الإلكتروني",
      PreScreeningVideoAssessment:
        "أسئلة الفرز المسبق للمرشحين عن طريق الفيديو	",
      PreScreeningTextAssessment:
        "أسئلة الفرز المسبق للمرشحين عن طريق نص كتابي	",
      LiveVideoInterview: "المقابلات المرئية	",
      jobOpeningsNo: "عدد الوظائف المطروحة شهريًا	",
      cvsNo: "عدد السير الذاتية للتقديم شهريًا",
      webcamMinutesNo: "رصيد الدقائق للمقابلات المرئية شهرياً",
      createJobsOnline: "إمكانية إنشاء الوظائف أونلاين",
      socialMedia: "نشر الوظائف عن طريق وسائل التواصل الإجتماعي	",
      careerQuestioner: "إستبيان مهني للمرشحين",
      scoreApplicants: "أسئلة الفرز المسبق المتعلقة بالوظيفة",
      workflow: "اعتماد طلبات التوظيف",
      companyBrief: "نبذة تعريفية عن الشركة	",
      linkWebsite: "ربط الموقع الإلكتروني للشركة بصفحة الوظائف	",
      customColor: "صفحة وظائف مصممة بهوية الشركة	",
      customLogo: " صفحة وظائف بشعار الشركة	",
      includeVat: "شامل ضريبة القيمة المضافة",
      fourteenFreeDays: "نسخة تجريبية مجانية لمدة 14 يوم",
      jobOfferOnline: "اعتماد العروض الوظيفية	",
      engagementWithCandidates: "نظام التعاقد مع المرشحين",
      subscribeBtn: "اشترك",
      ExtendExpirationDate: "تمديد الفتره",
      expirationDate: "تاريخ الانتهاء",
      expirationDateTrial: "متاح دائماً",
      ExtendFor: "تمديد الي ",
      freeTrialDays: "يوم تجربة مجانية",
      monthly: "شهريًا",
      annual: "سنويًا",
      save: "وفّر",
      SAR: "ريال سعودي",
      unlimited: "غير محدود",
      payNow: " ادفع الآن",
      selectCard: "اختر البطاقة",
      downgradeWarning:
        "تقليل الخطة الخاصة بكم، يمكن أن يسبب فقد التحكم/إدارة بعض البيانات المدخلة",
      cancelSubscription: "الغاء الاشتراك",
      cancelSubscriptionModalHeader: "هل أنت متأكد أنك ترغب بإلغاء اشتراكك ؟",
      cancelSubscriptionModalContent:
        "بإلغاءكم الاشتراك سيتم إيقاف حسابكم على النظام",
      youHave: "لديكم",
      refundStatement: "ر.س، سيتم إعادتها لكم وفقاً لشروط وأحكام HRCom",
      myWallet: "حافظتى",
      walletUseNote: " سوف يتم استخدامها في المعاملات القادمة",
      renew: "تجديد",
      unsubscribe: "إلغاء الاشتراك",
      perMonth: "بالشهر",
      select: "اشتراك",
      trialName: " التجربة المجانية",
      freeTrial: "تجربة مجانية",
      enterprise: "الباقة المتميزة",
      growth: "الباقة المتقدمة",
      basic: "الباقة الأساسية",
      free: "14 يوم مجانًا",
      noFees: "بدون مصاريف",
      NoCreditCard: "لا تحتاج لوسيلة دفع",
      billedAnnually: " الدفع سنويًا",
      billedMonthly: " الدفع شهريًا",
      users: "مستخدمين",
      usersTrial: "مستخدمين",
      jobsMonthly: "عدد الوظائف الجديدة شهريًا ",
      numberOfCvs: "عدد السير  الذاتية شهرياً",
      jobsTrial: "عدد الوظائف الجديدة",
      numberOfCvsTrial: "عدد السير  الذاتية",
      startTrial: " ابدأ التجربة المجانية",
      googleSearch: "ظهور الوظائف في نتائج البحث في جوجل",
      overview: "نظرة عامة",
      companyBranding: "هوية الشركة",
      matrix: "نظام الاعتمادات والصلاحيات	",
      tools: "أدوات التقييم	",
      jobPost: "نشر الوظائف	",
      customerSuccess: "رعاية العملاء",
      advancedFeatures: "الخيارات المتقدمة",
      sendSMSToCandidate: "إرسال رسائل نصية إلى المتقدمين",
      singleSignOn: "(SSO) الدخول الموحد",
      details: {
        basic: [
          "إدارة الأدوار والمهام",
          "صفحة الوظائف",
          "استبيان الوظائف",
          "تقييم المرشحين",
          "قابلية نشر الوظائف",
          "دعم للغات مختلفة",
        ],
        advance: [
          "إنشاء النظام بهوية الشركة",
          "طلب فيديو ما قبل التعيين عن المرشح",
          "مقابلات حصرية عبر الفيديو",
          "عرض التقويم السنوي",
          "إدارة عملية المرشحين ",
          "قاعدة بيانات سحابية",
        ],
        premium: [
          "تخصيص عمليات متنوعة للتعيين",
          "صفحة الوظائف بهوية الشركة",
          "الإعلان عن الوظائف",
          "فرز وتصفية متقدمة",
          "دمج مع المواقع والتقنيات",
          "فريق نجاح العملاء مسؤول للمتابعة ",
        ],
      },
      enjoy: "استمتع بكل مميزات ",
      plus: "، بالإضافة إلى:",
    },
    subscriptions: {
      trial: "",
      basic: "اساسي",
      advance: "متقدم",
      premium: "متميز",
      myWallet: "حافظتى",
      SAR: "ريال",
      appearOnJobSeeker: "الظهور على بوابة المتقدمين",
    },
    promoCodes: {
      sendTo: "ارسل إلى",
      usedBy: "استخدم بواسطة",
      addPromoCode: "اضف رمز ترويجى",
      editPromoCode: "تعديل الرمز التويجى",
      promoCodes: "الرمز الترويجي",
      startDate: "تاريخ البدء",
      endDate: "تاريخ الإنتهاء",
      selectPromoCodeType: "اختيار نوع الرمز الترويجي",
      percentage: "نسبة",
      fixedAmout: "قيمة ثابتة",
      discountValue: " قيمة الخصم",
      maxLimitValue: "الحد الأقصى",
      deletePromoCode: "حذف الرمز الترويجى",
      deletePromoCodeContent: "هل ترغب فى حذف هذا الرمز الترويجى؟",
      sendPromoCode: "ارسال الرمز الترويجى",
      companies: "الشركات",
      clients: "العملاء",
    },
    announcement: {
      annonceDate: "تاريخ إرسال البريد الإلكتروني",
      title: "العنوان",
      body: "النص",
      announcement: "اضافة اعلان",
    },
    ourStory: {
      title: "حكايتنا",
      description_1:
        "لطالما كانت عمليات التوظيف تعاني من التحديات والصعوبات، ولأننا مررنا بهذه التحديات بأنفسنا؛ أنشأنا نظام اتش ار كوم. جاءت اتش ار كوم بعد معاناة مع عمليات التوظيف بالطرق التقليدية، وابتدأت حكايتنا حين كنّا مسؤولي توظيف نستغرق الوقت الكثير في البحث عن مرشحين واستقبال السير الذاتية وضياعها في صندوق البريد الإلكتروني، بعد التشتّت الذي عايشناه في ترتيب جداول الإكسل وتنظيم البيانات، وبعد الانتقال بين منصات الاتصالات الهاتفية والمرئية لإجراء المقابلات مع المتقدمين وإنفاق الكثير على بعض المواقع للاشتراك وإكمال هذه الإجراءات.",
      description_2:
        "وبعد ما استشعرنا هذه المعاناة وتأثيرها على سير عمليات التوظيف؛ أجرينا دراسة عن نظم التوظيف الرقمية لنعرف عنها وما هي الدوافع والأسباب خلف إنشاءها، واستنتجنا في النهاية أن جميع أنظمة التوظيف الرقمية حول العالم لم تُبنى على أساس معرفي وخبرة سابقة في مجال الموارد البشرية وعمليات التوظيف. ومن هذا المنطلق ونتيجةً للتجارب التي عشناها طوال الفترة السابقة، أردنا النهوض بنظام توظيف رقمي بُني بكل فخر على أيدي خبراء في عمليات التوظيف، نظام يساعد في تنظيم العمليات وحل تحديات التوظيف التي تواجه المنشآت، نظام يربط بين أصحاب الأعمال والباحثين عن عمل في منصة واحدة تجمع كافة إجراءات التوظيف، من البحث عن المرشح حتى توقيعه للعرض الوظيفي.",
      description_3:
        "حرصنا في بناءنا لهذا النظام على توفير عملية توظيف سهلة ومرنة على مسؤول التوظيف، ورغبنا كذلك بتوفير تجربة سلسة ومرنة للباحث عن عمل في عملية بحثه عن الفرصة المناسبة حتى ينعكس في النهاية أثر هذه التجربة إيجابًا على سمعة المنشأة.",
      description_4: "هذه حكايتنا وهذا نَتاجها..",
      description_5: "جرّب نظام اتش ار كوم مجانًا وابدأ حكايتك معنا!",
    },
    blogs: "مدونة",
  },
};
