import * as types from "../../types/website/auth";

export const companyLogin = (payload) => ({
  type: types.COMPANY_LOGIN_REQUEST,
  payload,
});

export const requestCompanyForgetPassword = (data) => {
  return {
    type: types.COMPANY_FORGET_PASSWORD_REQUEST,
    payload: data,
  };
};
export const socialLoginRequest = (payload) => ({
  type: types.SOCIAL_LOGIN_REQUEST,
  payload,
});

export const socialLoginModalRequest = (payload) => ({
  type: types.SOCIAL_LOGIN_PREREQUESTION_MODAL_REQUEST,
  payload,
});
export const socialLoginModalFillRequest = (payload) => ({
  type: types.SOCIAL_LOGIN_PREREQUESTION_FILLED_REQUEST,
  payload,
});
export const socialLoginPopupOpenedRequest = (payload) => ({
  type: types.SOCIAL_LOGIN_POPUP_OPENED_REQUEST,
  payload,
});
export const socialLoginPhoneRequired = (payload) => ({
  type: types.SOCIAL_LOGIN_PHONE_NUMBER_REQUIRED,
  payload,
});
export const redicrectCompanyToLogin = (data) => {
  return {
    type: types.REDIRECT_COMPANY_TO_LOGIN_REQUEST,
    payload: data,
  };
};
export const redicrectCompanyToValidateDomain = (data) => {
  return {
    type: types.REDIRECT_COMPANY_TO_VALIDATE_DOMAIN_REQUEST,
    payload: data,
  };
};
export const companyPortalSetupRequest = () => {
  return {
    type: types.GET_COMPANY_PORTAL_SETUP_REQUEST
  }
};
export const companyPortalSetupRecieve = (payload) => {
  return {
    type: types.GET_COMPANY_PORTAL_SETUP_RECIEVE,
    payload
  }
};


export const createNewBlogRequest = (payload) => {
  return {
    type: types.CREATE_NEW_BLOG_REQUEST,
    payload
  }
};


export const updateBlogRequest = (payload) => {
  return {
    type: types.UPDATE_BLOG_REQUEST,
    payload
  }
};

export const clearBlogRequest = (data) => {
  return {
    type: types.CLEAR_BLOG_REQUEST,
    payload: data,
  };
};

export const clearBlogRecieve = (data) => {
  return {
    type: types.CLEAR_BLOG_RECIEVE,
    payload: data,
  };
};
export const deleteBlogRequest = (payload) => {
  return {
    type: types.DELETE_BLOG_REQUEST,
    payload
  }
}



export const getBlogsListRequest = (payload) => {
  return {
    type: types.GET_BLOGS_LIST_REQUEST,
    payload
  }
};
export const  getBlogsListRecieve = (payload) => {
  return {
    type: types.GET_BLOGS_LIST_RECIEVE,
    payload
  }
};
export const getBlogsListWebsiteRequest = (payload) => {
  return {
    type: types.GET_BLOGS_LIST_WEBSITE_REQUEST,
    payload
  }
};
export const  getBlogsListWebsiteRecieve = (payload) => {
  return {
    type: types.GET_BLOGS_LIST_WEBSITE_RECIEVE,
    payload
  }
};



export const getSingleBlogRequest = (data) => {
  return {
    type: types.GET_BLOG_SINGLE_REQUEST,
    payload:data
  }
};
export const  getSingleBlogRecieve = (payload) => {
  return {
    type: types.GET_BLOG_SINGLE_RECIEVE,
    payload
  }
};



export const getSingleBlogWebsiteRequest = (data) => {
  return {
    type: types.GET_BLOG_SINGLE_WEBSITE_REQUEST,
    payload:data
  }
};
export const  getSingleBlogWebsiteRecieve = (payload) => {
  return {
    type: types.GET_BLOG_SINGLE_WEBSITE_RECIEVE,
    payload
  }
};




export const getLatestBlogRequest = (data) => {
  return {
    type: types.GET_LATEST_BLOG_REQUEST,
    payload:data
  }
};
export const  getLatestBlogRecieve = (payload) => {
  return {
    type: types.GET_LATEST_BLOG_RECIEVE,
    payload
  }
};
