import * as types from "../../types/dashboard/jobDescription";

export const dashboardJobDescriptionRequest = (data) => {
  return {
    type: types.DASHBOARD_JOB_DESCRIPTIONS_REQUEST,
    payload: data,
  };
};

export const dashboardJobDescriptionRecieve = (data) => {
  return {
    type: types.DASHBOARD_JOB_DESCRIPTIONS_RECIEVE,
    payload: data,
  };
};


export const setJobDescriptionRequest = (data) => {
  return {
    type: types.SET_JOB_DESCRIPTIONS_REQUEST,
    payload: data,
  };
};

export const deleteJobDescriptionRequest = (data) => {
  return {
    type: types.DELETE_JOB_DESCRIPTIONS_REQUEST,
    payload: data,
  };
};


export const getDashboardJobDescriptionRecieve = (data) => {
  return {
    type: types.GET_DASHBOARD_JOB_DESCRIPTIONS_RECIEVE,
    payload: data,
  };
};
export const getDashboardJobDescriptionRequest = (data) => {
  return {
    type: types.GET_DASHBOARD_JOB_DESCRIPTIONS_REQUEST,
    payload: data,
  };
};