import store from "../store";
import { getURLParams } from "./Shared";
export const companyPortalBaseURL = (domain) => {
  if (window.location.href.includes("hrcom.ibtikar.sa")) {
    return `https://${domain}-company-stage.hrcom.ibtikar.sa`;
  } else if (window.location.href.includes("stage.hrcom.site")) {
    return `https://${domain}-company.stage.hrcom.site`;
  } else if (window.location.href.includes("hrcom.site")) {
    return `https://${domain}.hrcom.site`;
  } else if (window.location.href.includes("hrcom.io")) {
    return `https://${domain}.hrcom.io`;
  }else if (window.location.href.includes("hrcom.co")) {
    return `https://${domain}.company.hrcom.co`;
  } 
};
export const companyPortalForgetPasswordPage = () => {
  const locale = store.getState().locale;
  const domain = getURLParams("domain");
  //staging
  if (window.location.href.includes("hrcom.ibtikar.sa")) {
    return `${companyPortalBaseURL(domain)}/forgetPassword?locale=${
      locale.lang
    }`;
  }
  //new staging
  else if (window.location.href.includes("stage.hrcom.site")) {
    return `${companyPortalBaseURL(domain)}/forgetPassword?locale=${
      locale.lang
    }`;
  }
  //prelive
  else if (window.location.href.includes("hrcom.site")) {
    return `${companyPortalBaseURL(domain)}/forgetPassword?locale=${
      locale.lang
    }`;
  }
  //production
  else if (window.location.href.includes("hrcom.io")) {
    return `${companyPortalBaseURL(domain)}/forgetPassword?locale=${
      locale.lang
    }`;
  }
   //adaa live
   else if (window.location.href.includes("hrcom.co")) {
    return `${companyPortalBaseURL(domain)}/forgetPassword?locale=${
      locale.lang
    }`;
  }
};
export const candidatesPortalLink = () => {
  const locale = store.getState().locale;
  //stage
  if (window.location.href.includes("hrcom.ibtikar.sa")) {
    return `https://jobseeker-stage.hrcom.ibtikar.sa?locale=${locale.lang}`;
    //new stage
  } else if (window.location.href.includes("stage.hrcom.site")) {
    return `https://jobseeker.stage.hrcom.site?locale=${locale.lang}`;
    //prelive
  } else if (window.location.href.includes("hrcom.site")) {
    return `https://jobseeker.hrcom.site?locale=${locale.lang}`;
  }
  //production
  else if (window.location.href.includes("hrcom.io")) {
    return `https://jobseeker.hrcom.io?locale=${locale.lang}`;
  }
  //adaa live
  else if (window.location.href.includes("hrcom.co")) {
    return `https://jobseeker.hrcom.co?locale=${locale.lang}`;
  }
};
export const getEnv = () => {
  if (window.location.href.includes("stage.hrcom.site")) {
    return `stage.hrcom.site`;
  } else if (window.location.href.includes("hrcom.site")) {
    return `hrcom.site`;
  } else if (window.location.href.includes("hrcom.io")) {
    return `hrcom.io`;
  } else if (window.location.href.includes("hrcom.co")) {
    return `hrcom.co`;
  }
};

export function redirectToCalendarOffice365(domain, redirect_url) {
  return `${companyPortalBaseURL(domain)}/${redirect_url}`;
}

export const companyPortalRedirectionPages = {
  officeCalendar: "AddEditOffice365",
};

export const getCandidatePortalHome = (domain) => {
  if (window.location.href.includes("stage.hrcom.site")) {
    return `https://${domain}-candidate.stage.hrcom.site`;
  } else if (window.location.href.includes("hrcom.site")) {
    return `https://${domain}.candidate.hrcom.site`;
  } else if (window.location.href.includes("hrcom.io")) {
    return `https://${domain}.candidate.hrcom.io`;
  } else if (window.location.href.includes("hrcom.co")) {
    return `https://${domain}.candidates.hrcom.co`;
  } else {
    return `https://${domain}-candidate.stage.hrcom.site`;
  }
};
