import { axiosInstance } from "../config";

let handlerEnabled = true;

const registeredCompaniesRequest = async ({ pageNumber, resetParams }) => {
  let params = {
    page_number: pageNumber ? pageNumber : 1,
    page_size: 10,
    ...resetParams,
  };
  return await axiosInstance.get("hrcomsite/company/list", {
    params,
    handlerEnabled,
  });
};

const activateCompanies = async (id) => {
  return await axiosInstance.put(`/hrcomsite/company/activate/${id}`, id, {
    handlerEnabled,
  });
};

const deactivateCompanies = async (id) => {
  return await axiosInstance.put(`/hrcomsite/company/deactivate/${id}`, id, {
    handlerEnabled,
  });
};

const requestTabsCount = async (id) => {
  return await axiosInstance.get(`/hrcomsite/company/${id}/count`, {
    handlerEnabled,
  });
};
const requestAgents = async (id, pageNumber) => {
  let params = {
    page_number: pageNumber ? pageNumber : 1,
    page_size: 10,
  };
  return await axiosInstance.get(`/hrcomsite/company/${id}/agents`, {
    params,
    handlerEnabled,
  });
};

const jobOpennings = async (params) => {
  return await axiosInstance.get(`/hrcomsite/company/jop_opening_list`, {
    params,
    handlerEnabled,
  });
};

const requestAllCompaniesMinutes = async () => {
  return await axiosInstance.get(
    `/hrcomsite/company/Get_TotalCompanyMinutesData`,
    { handlerEnabled }
  );
};

const companySubscriptionDetails = async (company_id) => {
  return await axiosInstance.get(
    `/hrcomsite/subscriptions/plans/companies/${company_id}`,
    { handlerEnabled }
  );
};
const companySubscriptionHistoryList = async ({
  pageNumber,
  resetParams,
  id,
}) => {
  let params = {
    page_number: pageNumber ? pageNumber : 1,
    page_size: 10,
    ...resetParams,
  };
  return await axiosInstance.get(
    `/hrcomsite/subscriptions/getSubscribeHistoryLogs/${id}`,
    {
      params,
      handlerEnabled,
    }
  );
};

const companySubscriptionPlansLookupRequest = async () => {
  return await axiosInstance.get(`/hrcomsite/subscription_plans/lookup`, {
    handlerEnabled,
  });
};
const appearOnJobSeeker = async (id) => {
  return await axiosInstance.put(
    `/hrcomsite/company/${id}/toggle_show_on_job_seeker_portal`
  );
};


const extendExpirationDate = async (data) => {
  let param={ "expiration_date":data.expiration_date}
  return await axiosInstance.post(
    `/hrcomsite/company/extend/${data.id}`
  , 
    param 
 
  );
};
const viewInvoiceDetailsRequest = async ({ company_id, invoice_id }) => {
  return await axiosInstance.get(
    `/hrcomsite/subscriptions/${company_id}/${invoice_id}`
  );
};
const resendConfirmation = async ({ agentId, companyId }) => {
  return await axiosInstance.post(
    `/hrcomsite/company/resend_confirmation/${companyId}/${agentId}`,
    { handlerEnabled }
  );
};
const resendConfirmationByCreator = async ({ id }) => {
  return await axiosInstance.post(
    `/hrcomsite/company/ResendConfirmEmail`,
    id,
    { handlerEnabled }
  );
};
const companyDetailsRequest = async ({ company_id }) => {
  return await axiosInstance.get(
    `/hrcomsite/company/getCompanyDetails/${company_id}`,
    {
      handlerEnabled,
    }
  );
};

export default {
  registeredCompaniesRequest,
  activateCompanies,
  deactivateCompanies,
  requestTabsCount,
  requestAgents,
  jobOpennings,
  requestAllCompaniesMinutes,
  companySubscriptionDetails,
  companySubscriptionPlansLookupRequest,
  appearOnJobSeeker,
  extendExpirationDate,
  viewInvoiceDetailsRequest,
  companySubscriptionHistoryList,
  resendConfirmation,
  companyDetailsRequest,
  resendConfirmationByCreator
};
