import * as types from "../../types/dashboard/dashboard";
import { manipulateData } from "../../../dashboard/containers/Report/utils";

const INITIAL_STATE = {
  dashboard: {},
  plans: [],
  manipulateData: {},
  report: {},
  cvs: null,
  excel: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DASHBOARD_DATA_RECEIVE:
      return { ...state, dashboard: action.payload };
    case types.DASHBOARD_PLANS_RECEIVE:
      return { ...state, plans: action.payload };
    case types.REPORT_DATA_RECIEVE:
      return {
        ...state,
        report: action.payload,
        manipulateData: manipulateData(action.payload),
      };
    case types.EXPORT_CVS_RECIEVE:
      return {
        ...state,
        cvs: action.payload,
      };
    case types.EXPORT_EXCEL_RECIEVE:
      return {
        ...state,
        excel: action.payload,
      };
    default:
      return state;
  }
};
