import { call, takeLatest, put } from "redux-saga/effects";
import API from "../../../network/apis/auth";
import * as types from "../../types/website/auth";
import { dispatchError, showSnackbar } from "../../../utils/Shared";
import History from "routes/History";
import { colorsWithPlan } from "containers/Login/CompanyPortalSetup";
import { companyPortalSetupRecieve } from "store/actions/website/auth";
import cssVariables from "../../../containers/Login/_CompanyPortalVariables.scss";

export function* CompanyPortalSetupRecieve() {
  yield call(API.getCompanyPortalSetupRequest);
}
export function* getCompanyPortalSetup() {
  try {
    const response = yield call(API.getCompanyPortalSetupRequest);
    const brandedSite = colorsWithPlan(response.data.data);
    yield put(companyPortalSetupRecieve(brandedSite));

    document.documentElement.style.setProperty(
      "--company-primary-color",
      brandedSite.primary_color
    );
    document.documentElement.style.setProperty(
      "--company-secondary-color",
      brandedSite.secondary_color
    );
    cssVariables.secondaryColor =  brandedSite.secondary_color;
    yield call(CompanyPortalSetupRecieve)
  } catch (err) {
    console.log(err);
    dispatchError(err.response);
  }
}
export function* redirectCompanyToLogin({ payload }) {
  try {
    const response = yield call(API.companyLogin, payload);
    window.location.href = response.data.data.redirect_url;
  } catch (error) {
    dispatchError(error.response.data);
  }
}
export function* redirectCompanyToValidateDomain(Actions) {
  try {
    const response = yield call(API.checkDomain, Actions.payload);
    yield put(Actions.receiveValidateDomain(response.data.data));
  } catch (err) {
    if (err.request.status === 404) {
      History.push("/notFound");
    } else if (err.request.status === 409) {
      yield put(Actions.receiveValidateDomain(err.response.data.error.message));
    }
    dispatchError(err.response.data);
  }
}

function* companyLogin({ payload }) {
  try {
    const response = yield call(API.companyLogin, payload);
    window.location.href = response.data.data.redirect_url;
  } catch (error) {
    dispatchError(error.response.data);
  }
}

function* companyForgetPassword({ payload }) {
  try {
    yield call(API.companyForgetPassword, payload);
    History.push("/companyLogin");
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response.data);
  }
}

export function* signinWithSocialSDKRequest({ payload }) {
  try {
    const response = yield call(API.socialLoginsRequest, payload);
    window.location.href = response.data.data.redirect_url;
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* authSagasWatch() {
  yield takeLatest(types.COMPANY_LOGIN_REQUEST, companyLogin);
  yield takeLatest(
    types.COMPANY_FORGET_PASSWORD_REQUEST,
    companyForgetPassword
  );

  yield takeLatest(types.SOCIAL_LOGIN_REQUEST, signinWithSocialSDKRequest);

  yield takeLatest(
    types.REDIRECT_COMPANY_TO_LOGIN_REQUEST,
    redirectCompanyToLogin
  );
  yield takeLatest(
    types.REDIRECT_COMPANY_TO_VALIDATE_DOMAIN_REQUEST,
    redirectCompanyToValidateDomain
  );
  yield takeLatest(
    types.GET_COMPANY_PORTAL_SETUP_REQUEST,
    getCompanyPortalSetup
  );
}
