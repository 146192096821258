import { axiosInstance } from "../config";

let handlerEnabled = true;

const requestDashboardData = async () => {
  return await axiosInstance.get(`/hrcomsite/company/get_cards_data`, {
    handlerEnabled,
  });
};

const requestPlansData = async () => {
  return await axiosInstance.get(`/hrcomsite/subscriptions/plans/companies/count`, {
    handlerEnabled,
  });
};

const requestPackagesData = async () => {
  return await axiosInstance.get(`/`, {
    handlerEnabled,
  });
};

const reportRequest = async () => {
  return await axiosInstance.get("/hrcomsite/reports/statistics");
};
const exportExcelRequest = async () => {
  return await axiosInstance.get("/hrcomsite/reports/statistics/export_excel", { responseType: 'arraybuffer' });
};

const exportCvsRequest = async () => {
  return await axiosInstance.get("/hrcomsite/reports/statistics/export_csv");
};

export default {
  requestDashboardData,
  requestPlansData,
  requestPackagesData,
  reportRequest,
  exportCvsRequest,
  exportExcelRequest
};
