import * as types from "../../types/dashboard/jobDescription";

const INITIAL_STATE = {
  jobDescriptions: [],
  paging: {},
  job:null

};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DASHBOARD_JOB_DESCRIPTIONS_RECIEVE:
      return {
        ...state,
        paging: action.payload.paging,
        jobDescriptions: action.payload.data,
      };
      case types.GET_DASHBOARD_JOB_DESCRIPTIONS_RECIEVE:
        return {
          ...state,
          job: { ...state.plan, ...action.payload.data },
        };
   
    default:
      return state;
  }
};
