import React, { Suspense } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import history from './History';
// ========= Components =========
import * as LazyComponent from '../utils/LazyLoaded';
import { Loader } from 'components/Loader/Loader';
import { PrivateRoute } from 'dashboard/components/PrivateRoute/PrivateRoute';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

const Routes = (
  <Suspense fallback={<Loader />}>
    <Router history={history}>
      <Switch>
        {/* Website */}
        <Route exact path="/linkedin" component={LinkedInPopUp} />
        <LazyComponent.RedirectCompany path="/redirect-company" />
        <LazyComponent.Home path="/" exact />
        <LazyComponent.About path="/About-us" exact />
        <LazyComponent.RequestDemo path="/demo-request" exact />
        <LazyComponent.NewRequestDemo path="/request-demo" exact />
        <LazyComponent.ContactUs path="/Contact-us" exact />
        <LazyComponent.NewsDetails path="/news/:id" exact />
        <LazyComponent.ProductDetails path="/products/:id" exact />
        <LazyComponent.Registration path="/registration" exact />
        <LazyComponent.AllProducts path="/products" exact />
        <LazyComponent.FAQ path="/FAQ" exact />
        <LazyComponent.ConfirmEmail path="/ConfirmEmail" exact />
        {/* ConfirmEmail-n-password => ConfirmEmail-No-Password */}
        <LazyComponent.ConfirmEmailWithoutPassword path="/ConfirmEmail-withoutPassword" exact />
        <LazyComponent.CompanyLogin path="/companyLogin" exact />
        <LazyComponent.CalendarOffice365 path="/calendar-office365" exact />
        <LazyComponent.CompanyForgetPassword
          path="/companyForgetPassword"
          exact
        />
        <LazyComponent.PrivacyPolicy path="/privacyPolicy" exact />
        <LazyComponent.TermsAndConditions path="/termsAndConditions" exact />
        <LazyComponent.Disclaimer path="/disclaimer" exact />
        <LazyComponent.Pricing path="/pricing" exact />
        {/* <LazyComponent.JobSeeker path="/jobSeeker" exact />*/}
        {/* <LazyComponent.SuccessRegisteration path="/success" exact />*/}
        <LazyComponent.Blogs path="/blogs"/>
        <LazyComponent.SingleBlog  path="/blog/:id"/>

        {/* Dashbord */}
        <PrivateRoute
          path="/admin/dashboard/home"
          exact
          component={LazyComponent.DashboardContainer}
        />
               <PrivateRoute
          path="/admin/dashboard/blogs"
          exact
          component={LazyComponent.BlogList}
        />
                     <PrivateRoute
          path="/admin/dashboard/blog"
          exact
          component={LazyComponent.Blog}
        />
         <PrivateRoute
          path="/admin/dashboard/blog/:id"
          exact
          component={LazyComponent.Blog}
        />
        <PrivateRoute
          path="/admin/dashboard/registeredCompanies"
          component={LazyComponent.RegisteredCompanies}
        />
        <PrivateRoute
          path="/admin/dashboard/login"
          component={LazyComponent.Login}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/forgetPassword"
          component={LazyComponent.ForgotPassword}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/resetPassword"
          component={LazyComponent.ResetPassword}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/changePassword"
          component={LazyComponent.ChangePassword}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/companyDetails/:id?"
          component={LazyComponent.CompanyDetails}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/setup"
          component={LazyComponent.Setup}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/report"
          component={LazyComponent.Report}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/candidateSetup"
          component={LazyComponent.CandidateSetup}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/candidates"
          component={LazyComponent.Candidates}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/candidateProfile/:id"
          component={LazyComponent.CandidateProfile}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/promoCodes"
          component={LazyComponent.PromoCodeList}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/promoCode/:id?"
          component={LazyComponent.AddEditPromoCode}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard/planCustomization"
          exact
          component={LazyComponent.CustomPlan}
        />
          <PrivateRoute
          path="/admin/dashboard/jobDescriptions"
          exact
          component={LazyComponent.JobDescription}
        />
        <PrivateRoute
          path="/admin/dashboard/jobDescriptions/add"
          exact
          component={LazyComponent.AddEditJobDescription}
        />
          <PrivateRoute
          path="/admin/dashboard/jobDescriptions/edit/:id?"
          exact
          component={LazyComponent.AddEditJobDescription}
        />
         <PrivateRoute
          path="/admin/dashboard/sendEmailNotificationList"
          exact
          component={LazyComponent.SendEmailNotificatios}
        />
          <PrivateRoute
          path="/admin/dashboard/sendEmailNotification/add"
          exact
          component={LazyComponent.AddEditSendEmailNotificatios}
        />
         <PrivateRoute
          path="/admin/dashboard/actionsEmailTemplates"
          exact
          component={LazyComponent.ActionsEmailTemplate}
        />
          <PrivateRoute
          path="/admin/dashboard/sendEmailNotification/edit/:id?"
          exact
          component={LazyComponent.AddEditSendEmailNotificatios}
        />
             <PrivateRoute
          path="/admin/dashboard/sendEmailNotification/duplicate/:id?"
          exact
          component={LazyComponent.AddEditSendEmailNotificatios}
        />
        <PrivateRoute
          path="/admin/dashboard/PlanCustomization/add"
          exact
          component={LazyComponent.AddEditPlanCustomization}
        />
        <PrivateRoute
          path="/admin/dashboard/PlanCustomization/edit/:id?"
          exact
          component={LazyComponent.AddEditPlanCustomization}
        />
        <PrivateRoute
          path="/admin/dashboard/publishAnnouncement"
          exact
          component={LazyComponent.publishAnnouncement}
        />
        <PrivateRoute
          path="/invoices"
          exact
          component={LazyComponent.Invoices}
          permission={['SETUP']}
        />
        <LazyComponent.NotFound
          path="*"
          title="This page doesn't exist..."
          exact
        />
        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  </Suspense>
);

export default Routes;
