import * as types from "../../types/dashboard/emailTemplate";

export const emailTemplateTypesRequest = (data) => {
  return {
    type: types.EMAIL_TEMPLATE_TYPES_REQUEST,
    payload: data,
  };
};

export const emailTemplateTypesRecieve = (data) => {
  return {
    type: types.EMAIL_TEMPLATE_TYPES_RECIEVE,
    payload: data,
  };
};



export const emailTemplateDetailsRequest = (data) => {
  return {
    type: types.EMAIL_TEMPLATE_DETAILS_REQUEST,
    payload: data,
  };
};

export const emailTemplateDetailsRecieve = (data) => {
  return {
    type: types.EMAIL_TEMPLATE_DETAILS_RECIEVE,
    payload: data,
  };
};


export const requestSendEmailTemplate = (payload) => ({
  type: types.SEND_EMAIL_TEMPLATE_REQUEST,
  payload,
});



export const clearEmailTemplateDetailsRequest = (data) => {
  return {
    type: types.CLEAR_EMAIL_TEMPLATE_REQUEST,
    payload: data,
  };
};
export const clearEmailTemplateDetailsRecieve = (data) => {
  return {
    type: types.CLEAR_EMAIL_TEMPLATE_RECIEVE,
    payload: data,
  };
};
