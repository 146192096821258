import * as types from "../../types/dashboard/sendEmailNotifications";

const INITIAL_STATE = {
  emailNotificationList: [],
  paging: {},
  emailNotification:null,
  DuplicateEmailNotification:null

};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DASHBOARD_SEND_EMAIL_NOTIFICATIONS_RECIEVE:
      return {
        ...state,
        paging: action.payload.paging,
        emailNotificationList: action.payload.data,
      };
      case types.GET_DASHBOARD_MESSAGES_RECIEVE:
        return {
          ...state,
          emailNotification: { ...state.plan, ...action.payload.data },
        };
        case types.DUPLICATE_EMAIL_NOTIFICATIONS_RECIEVE:
          return {
            ...state,
            DuplicateEmailNotification: { ...state.plan, ...action.payload.data },
          };
          case types.CLEAR_DASHBOARD_MESSAGES_REQUEST:
            return {
              ...state,
              emailNotification: null,
            };
    default:
      return state;
  }
};
