import * as types from "../../types/dashboard/emailTemplate";

const INITIAL_STATE = {
  emailTemplateTypes: [],
  emailTemplateDetails: null,

};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.EMAIL_TEMPLATE_TYPES_RECIEVE:
      return {
        ...state,
        emailTemplateTypes: action.payload.data,
      };
      case types.EMAIL_TEMPLATE_DETAILS_RECIEVE:
        return {
          ...state,
          emailTemplateDetails: action.payload,
        };
        case types.CLEAR_EMAIL_TEMPLATE_REQUEST:
          return {
            ...state,
            emailTemplateDetails: null,
          };
     
    default:
      return state;
  }
};
