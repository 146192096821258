import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../../network/apis/dashboard/registeredCompanies";
import * as ACTION from "../../actions/dashboard/registeredCompanies";
import * as TYPES from "../../types/dashboard/registeredCompanies";
import { dispatchError, showSnackbar } from "../../../utils/Shared";
import history from "../../../routes/History";

function* registeredCompaniesRequest({ payload }) {
  try {
    const response = yield call(API.registeredCompaniesRequest, payload);
    yield put(ACTION.registeredCompaniesReceive(response.data));
  } catch (err) {
    console.log(err);
    dispatchError(err.response.data);
  }
}

export function* activateCompanySaga({ payload }) {
  try {
    yield call(API.activateCompanies, payload);
    yield put(
      ACTION.registeredCompaniesRequest(
       {
         pageNumber:  history.location.search.split("=")[1] || 1
       }
      )
    );
    showSnackbar("done");
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* deactivateCompanySaga({ payload }) {
  try {
    yield call(API.deactivateCompanies, payload);
    yield put(
      ACTION.registeredCompaniesRequest(
       {
         pageNumber:  history.location.search.split("=")[1] || 1
       }
      )
    );
    showSnackbar("done");
  } catch (err) {
    dispatchError(err.response.data);
  }
}

function* tabsCountRequest({ payload }) {
  try {
    const response = yield call(API.requestTabsCount, payload);
    yield put(ACTION.tabsCountReceive(response.data.data));
  } catch (err) {
    dispatchError(err.response.data);
  }
}
function* agentsRequest({ payload: { id, pageNumber } }) {
  try {
    const response = yield call(API.requestAgents, id, pageNumber);
    yield put(ACTION.agentsReceive(response.data));
  } catch (err) {
    dispatchError(err.response.data);
  }
}

function* jobOpeningsRequest({ payload }) {
  try {
    const response = yield call(API.jobOpennings, payload);
    yield put(ACTION.jobOpeninngsReceive(response.data));
  } catch (err) {
    dispatchError(err.response.data);
  }
}

function* allCompaniesMinutesRequest() {
  try {
    const response = yield call(API.requestAllCompaniesMinutes);
    yield put(ACTION.allCompaniesMinutesReceive(response.data.data));
  } catch (err) {
    dispatchError(err.response.data);
  }
}

function* companySubscriptionDetailsRequest({ payload }) {
  try {
    const response = yield call(API.companySubscriptionDetails, payload);
    yield put(ACTION.companySubscriptionDetailsReceive(response.data.data));
  } catch (err) {
    dispatchError(err.response.data);
  }
}

function* companySubscriptionPlansLookupRequest() {
  try {
    const response = yield call(API.companySubscriptionPlansLookupRequest);
    yield put(ACTION.subscriptionPlansLookupRecieve(response.data.data));
  } catch (err) {
    dispatchError(err.response.data);
  }
}

function* appearOnJobSeeker({ payload }) {
  try {
    yield call(API.appearOnJobSeeker, payload.id);
    showSnackbar("done");
  } catch (err) {
    dispatchError(err.response.data);
  }
}



function* extendExpirationDate({ payload }) {
  try {
    yield call(API.extendExpirationDate, payload);
    showSnackbar("done");
  } catch (err) {
    dispatchError(err.response.data);
  }
}
function* viewInvoiceDetailsRequest({ payload }) {
  try {
    yield put(
      ACTION.viewInvoiceDetailsRecieve({
        data: {},
        showInvoice: false,
      })
    );
    const response = yield call(API.viewInvoiceDetailsRequest, payload);
    yield put(
      ACTION.viewInvoiceDetailsRecieve({
        data: response.data.data,
        showInvoice: true,
      })
    );
  } catch (err) {
    yield put(
      ACTION.viewInvoiceDetailsRecieve({
        data: {},
        showInvoice: false,
      })
    );
    dispatchError(err?.response?.data);
  }
}
function* companySubscriptionHistoryRequest({ payload }) {
  try {
    const response = yield call(API.companySubscriptionHistoryList, payload);
    yield put(ACTION.companySubscriptionHistoryRecieve(response.data));
  } catch (err) {
    dispatchError(err?.response?.data);
  }
}
export function* resendConfirmation(action) {
  try {
    yield call(API.resendConfirmation, action.payload);
    showSnackbar("done");
  } catch (err) {
    dispatchError(err?.response?.data);
  }
}
export function* resendConfirmationByCreator(action) {
  try {
    yield call(API.resendConfirmationByCreator, action.payload);
    showSnackbar("done");
  } catch (err) {
    dispatchError(err?.response?.data);
  }
}
export function* companyDetailsRequest(action) {
  try {
    const response = yield call(API.companyDetailsRequest, action.payload);
    yield put(ACTION.companyDetailsRecieve(response.data));
  } catch (err) {
    dispatchError(err?.response?.data);
  }
}
export default function* registeredCompaniesSagas() {
  yield takeLatest(
    TYPES.REGISTERED_COMPANIES_REQUEST,
    registeredCompaniesRequest
  );
  yield takeLatest(TYPES.ACTIVATE_COMPANIES_REQUEST, activateCompanySaga);
  yield takeLatest(TYPES.DEACTIVATE_COMPANIES_REQUEST, deactivateCompanySaga);
  yield takeLatest(TYPES.TABS_COUNT_REQUEST, tabsCountRequest);
  yield takeLatest(TYPES.AGENTS_REQUEST, agentsRequest);
  yield takeLatest(TYPES.JOB_OPENNINGS_REQUEST, jobOpeningsRequest);
  yield takeLatest(
    TYPES.ALL_COMPANIES_MINUTES_REQUEST,
    allCompaniesMinutesRequest
  );
  yield takeLatest(
    TYPES.COMPANY_SUBSCRIPTION_DETAILS_REQUEST,
    companySubscriptionDetailsRequest
  );
  yield takeLatest(
    TYPES.COMPANY_SUBSCRIPTION_PLANS_LOOKUP_REQUEST,
    companySubscriptionPlansLookupRequest
  );
  yield takeLatest(
    TYPES.COMPANY_SUBSCRIPTION_HISTORY_LIST_REQUEST,
    companySubscriptionHistoryRequest
  );
  yield takeLatest(TYPES.APPEAR_ON_JOB_SEEKER, appearOnJobSeeker);
  yield takeLatest(TYPES.EXTEND_EXPIRATION_DATE, extendExpirationDate);

  yield takeLatest(TYPES.VIEW_INVOICE_REQUEST, viewInvoiceDetailsRequest);
  yield takeLatest(TYPES.AGENT_RESEND_CONFIRMATION_REQUEST, resendConfirmation);

  yield takeLatest(
    TYPES.RESEND_CONFIRMATION_CREATOR_REQUEST,
    resendConfirmationByCreator
  );
  yield takeLatest(TYPES.COMPANY_DETAILS_REQUEST, companyDetailsRequest);
}
