import { axiosInstance } from "../config";

let handlerEnabled = true;

const emailNotificationRequest = async ({ pageNumber ,name}) => {
  let params = {
    page_number: pageNumber ? pageNumber : 1,
    page_size: 10,
    name
  };
  return await axiosInstance.get("/hrcomsite/messages", {
    params,
    handlerEnabled,
  });
};
const getMessagesRequest = async (id) => {
  return await axiosInstance.get(`/hrcomsite/messages/${id}`, {
    handlerEnabled,
  });
};
const getMessagesDuplicateRequest = async (id) => {
  return await axiosInstance.get(`/hrcomsite/messages/${id}/duplicate`, {
    handlerEnabled,
  });
};

const setSendEmailNotificationRequest = async (data) => {
  return await axiosInstance.post(`/hrcomsite/messages`,data, {
    handlerEnabled,
  });
};
const updateMessagesRequest = async (data) => {
  return await axiosInstance.put(`/hrcomsite/messages/${data.id}`,data, {
    handlerEnabled,
  });
};
const deleteSendEmailNotificationsRequest = async (id) => {
  return await axiosInstance.delete(`/hrcomsite/messages/${id}`, {
    handlerEnabled,
  });
};



export default {
  emailNotificationRequest,
  getMessagesRequest,
  setSendEmailNotificationRequest,
  updateMessagesRequest,
  deleteSendEmailNotificationsRequest,
  getMessagesDuplicateRequest
};
