import { axiosInstance } from "./config";
import { getURLParams } from "utils/Shared";
let handlerEnabled = true;
const adminLogin = async (data) => {
  return await axiosInstance.post("/hrcomsite/account/login", data, {
    handlerEnabled,
  });
};

const adminForgotPassword = async (data) => {
  return await axiosInstance.post("/hrcomsite/account/forget_password", data, {
    handlerEnabled,
  });
};

const adminResetPassword = async (data) => {
  return await axiosInstance.post("/hrcomsite/account/reset_password", data, {
    handlerEnabled,
  });
};

const adminChangePassword = async (data) => {
  return await axiosInstance.put("/hrcomsite/account/change_password", data, {
    handlerEnabled,
  });
};
const companyLogin = async (data) => {
  return await axiosInstance.post("/admin/auth/login", data, {
    handlerEnabled,
  });
};
const companyForgetPassword = async (data) => {
  return await axiosInstance.post("/admin/auth/forget_password", data, {
    headers: {
      domain: data.domain,
    },
    handlerEnabled,
  });
};
const checkDomain = async (domain) => {
  return await axiosInstance.get(`/admin/auth/company/${domain}`, {
    handlerEnabled,
  });
};
const socialLoginsRequest = async (data) => {
  return await axiosInstance.post("/Company/SocialSignUp", data);
};
const getCompanyPortalSetupRequest = async () =>
  await axiosInstance.get(`/Company/company_data_by_domain_name`, {
    headers: {
      domain: getURLParams("domain"),
    },
    handlerEnabled,
  });
export default {
  adminLogin,
  adminForgotPassword,
  adminResetPassword,
  adminChangePassword,
  companyLogin,
  companyForgetPassword,
  socialLoginsRequest,
  checkDomain,
  getCompanyPortalSetupRequest,
};
