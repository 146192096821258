export const DASHBOARD_DATA_REQUEST = 'DASHBOARD_DATA_REQUEST'
export const DASHBOARD_DATA_RECEIVE = 'DASHBOARD_DATA_RECEIVE'

export const DASHBOARD_PLANS_REQUEST = 'DASHBOARD_PLANS_REQUEST'
export const DASHBOARD_PLANS_RECEIVE = 'DASHBOARD_PLANS_RECEIVE'

export const DASHBOARD_PACKAGES_RECIEVE = 'DASHBOARD_PACKAGES_RECIEVE';
export const DASHBOARD_PACKAGES_REQUEST = 'DASHBOARD_PACKAGES_REQUEST';


export const EXPORT_EXCEL_REQUEST = 'EXPORT_EXCEL_REQUEST'
export const EXPORT_EXCEL_RECIEVE = 'EXPORT_EXCEL_RECIEVE'
export const EXPORT_CVS_REQUEST = 'EXPORT_CVS_REQUEST'
export const EXPORT_CVS_RECIEVE = 'EXPORT_CVS_RECIEVE'
export const REPORT_DATA_REQUEST = 'REPORT_DATA_REQUEST'
export const REPORT_DATA_RECIEVE = 'REPORT_DATA_RECIEVE'