import * as types from "../types";

export const requestHeroContent = () => {
  return {
    type: types.FETCH_HERO_REQUEST
  }
};

export const receiveHeroContent = (data) => {
  return{
    type: types.FETCH_HERO_RECEIVE,
    payload: data
  }
};

export const requestInfoContent = () => {
  return {
    type: types.FETCH_INFO_REQUEST
  }
};

export const receiveInfoContent = (data) => {
  return{
    type: types.FETCH_INFO_RECEIVE,
    payload: data
  }
};
export const requestCounterContent = () => {
  return{
    type: types.FETCH_COUNTER_REQUEST,
  }
};
export const receiveCounterContent = (data) => {
  return{
    type: types.FETCH_COUNTER_RECEIVE,
    payload: data
  }
};

export const requestLogosContent = () => {
  return {
    type: types.FETCH_LOGOS_REQUEST
  }
};


export const receiveLogsContent = (data) => {
  return{
    type: types.FETCH_LOGOS_RECEIVE,
    payload: data
  }
};

export const requestProductsContent = () => {
  return {
    type: types.FETCH_PRODUCTS_REQUEST
  }
};

export const receiveProductsContent = (data) => {
  return{
    type: types.FETCH_PRODUCTS_RECEIVE,
    payload: data
  }
};

export const requestNewsContent = () => {
  return {
    type: types.FETCH_NEWS_REQUEST
  }
};

export const receiveNewsContent = (data) => {
  return{
    type: types.FETCH_NEWS_RECEIVE,
    payload: data
  }
};


export const requestFeaturesContent = () => {
  return {
    type: types.FETCH_FEATURES_REQUEST
  }
};

export const receiveFeaturesContent = (data) => {
  return{
    type: types.FETCH_FEATURES_RECEIVE,
    payload: data
  }
};

export const requestReviewsContent = () => {
  return {
    type: types.FETCH_REVIEWS_REQUEST
  }
};

export const receiveReviewsContent = (data) => {
  return{
    type: types.FETCH_REVIEWS_RECEIVE,
    payload: data
  }
};

export const requestQuestionsContent = () => {
  return {
    type: types.FETCH_QUESTIONS_REQUEST
  }
};

export const receiveQuestionsContent = (data) => {
  return{
    type: types.FETCH_QUESTIONS_RECEIVE,
    payload: data
  }
};

export const requestProductDetailsContent = (id) => {
  return {
    type: types.FETCH_PRODUCT_DETAILS_REQUEST,
    payload : id
  }
};

export const receiveProductDetailsContent = (data) => {
  return{
    type: types.FETCH_PRODUCT_DETAILS_RECEIVE,
    payload: data
  }
};

export const requestNewsDetailsContent = (id) => {
  return {
    type: types.FETCH_NEWS_DETAILS_REQUEST,
    payload : id
  }
};

export const receiveNewsDetailsContent = (data) => {
  return{
    type: types.FETCH_NEWS_DETAILS_RECEIVE,
    payload: data
  }
};


export const handleModalStateAction = (data) => {
  return{
    type: types.IS_MODAL_OPEN,
    payload: data
  }
};

export const addEditCompanyOffice365 = (payload) => {
  return {
    type: types.ADD_EDIT_OFFICE365_INTEGRATION_REQUEST,
    payload,
  };
};



