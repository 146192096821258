import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../../network/apis/dashboard/emailTemplate";
import * as Actions from "../../actions/dashboard/emailTemplate";
import * as type from "../../types/dashboard/emailTemplate";
import { dispatchError, showSnackbar } from "../../../utils/Shared";
import History from "routes/History";
import store from "store";
import messages from "assets/Local/messages";
import { showSnackbarAction } from "store/actions/snackbar";

function* requestEmailTemplateTypes({ payload }) {
  try {
    const response = yield call(API.emailTemplateTypesRequest, payload);
    yield put(Actions.emailTemplateTypesRecieve(response.data));
  } catch (error) {
    dispatchError(error.response.data);
  }
}

function* requestEmailTemplateDetails({ payload }) {
  try {
    const response = yield call(API.emailTemplateDetailsRequest, payload);
    yield put(Actions.emailTemplateDetailsRecieve(response.data));
  } catch (error) {
    dispatchError(error.response.data);
  }
}


function* requestSendEmailTemplate({ payload }) {
  try {
    yield call(API.requestSendEmailTemplate, payload);
    showSnackbar("ChangesAppliedSuccessfully");
  } catch (error) {
    dispatchError(error.response.data);
  }
}
export default function* EmailTemplateSagasWatch() {
  yield takeLatest(
    type.EMAIL_TEMPLATE_TYPES_REQUEST,
    requestEmailTemplateTypes
  );
  yield takeLatest(
    type.EMAIL_TEMPLATE_DETAILS_REQUEST,
    requestEmailTemplateDetails
  );
  yield takeLatest(
    type.SEND_EMAIL_TEMPLATE_REQUEST,
    requestSendEmailTemplate
  );

}
