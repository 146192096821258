import { all } from "redux-saga/effects";
import registeredCompaniesSagas from "./registeredCompaniesSagas";
import authSagas from "./auth";
import CandidateProfileSetupSagasWatch from "./candidateSetup";
import lookupsSagas from "./lookups";
import DashboardSagasWatch from "./dashboard";
import promoCodesSagasWatch from "./promoCodes";
import DashboardSubscriptionsSagasWatch from './subscriptions'
import DashboardJobDescriptionSagasWatch from "./jobDescription"
import DashboardsendEmailNotificationsSagasWatch from './sendEmailNotifications'
import EmailTemplateSagasWatch from "./emailTemplate"
export default function* dashboardSagasWatch() {
  yield all([
    registeredCompaniesSagas(),
    authSagas(),
    CandidateProfileSetupSagasWatch(),
    lookupsSagas(),
    DashboardSagasWatch(),
    promoCodesSagasWatch(),
    DashboardSubscriptionsSagasWatch(),
    DashboardJobDescriptionSagasWatch(),
    DashboardsendEmailNotificationsSagasWatch(),
    EmailTemplateSagasWatch()
  ]);
}
