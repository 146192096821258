import * as types from "../../types";

const INITIAL_STATE = {
    hero : {} ,
    info : {} , 
    products : {} ,
    news : {} ,
    counter: {},
    logos:{},
    features: {} , 
    reviews : {},
    questions : {},
    productDetails : {} ,
    newsDetails : {}
}

export default (state = INITIAL_STATE , action) => {
    switch (action.type) {
        case types.FETCH_HERO_RECEIVE:
            return { ...state, hero : {...action.payload} };
        case types.FETCH_INFO_RECEIVE:
            return { ...state, info : {...action.payload} };
        case types.FETCH_COUNTER_RECEIVE:
            return { ...state, counter: { ...action.payload } };
        case types.FETCH_LOGOS_RECEIVE:
            return { ...state, logos: { ...action.payload } };
        case types.FETCH_PRODUCTS_RECEIVE:
            return { ...state, products : {...action.payload} };
        case types.FETCH_NEWS_RECEIVE:
            return { ...state, news: {...action.payload} };
        case types.FETCH_FEATURES_RECEIVE:
            return { ...state, features:{...action.payload} };
        case types.FETCH_REVIEWS_RECEIVE:
            return { ...state, reviews:{...action.payload} };
        case types.FETCH_QUESTIONS_RECEIVE:
            return { ...state, questions : {...action.payload} };
        case types.FETCH_NEWS_DETAILS_RECEIVE:
            return { ...state, newsDetails : {...action.payload} };
        case types.FETCH_PRODUCT_DETAILS_RECEIVE:
            return { ...state, productDetails : {...action.payload} };
        default: return state;
    }
}