import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../../network/apis/dashboard/dashboard";
import * as Actions from "../../actions/dashboard/dashboard";
import * as type from "../../types/dashboard/dashboard";
import { dispatchError } from "../../../utils/Shared";
import FileSaver from "file-saver";

function* requestDashboardData({ payload }) {
  try {
    const response = yield call(API.requestDashboardData, payload);
    yield put(Actions.receiveDashboardData(response.data.data));
  } catch (error) {
    dispatchError(error.response.data);
  }
}

function* requestDashboardPlansData() {
  try {
    const response = yield call(API.requestPlansData);
    yield put(Actions.receiveDashboarPlansdData(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* requestDashboardPackagesData() {
  try {
    const response = yield call(API.requestPackagesData);
    yield put(Actions.receiveDashboardPackagesData(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* reportDataRequest() {
  try {
    const response = yield call(API.reportRequest);
    yield put(Actions.reportDataRecieve(response.data.data));
  } catch (error) {
    console.log(error);
  }
}



export function* exportExcelRequest() {
  try {
    const response = yield call(API.exportExcelRequest);
    yield put(Actions.exportExcelRecieve(response.data));
    console.log(response.data)
    var blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    FileSaver.saveAs(blob, 'Reports.xlsx');
  } catch (error) {
    console.log(error);
  }
}

export function* exportCvsRequest() {
  try {
    const response = yield call(API.exportCvsRequest);
    yield put(Actions.exportCvsRecieve(response.data));
    console.log(response.data)
    var blob = new Blob([response.data], { type: "text/csv;charset=utf-8" });
    FileSaver.saveAs(blob, "Reports.csv");

  } catch (error) {
    console.log(error);
  }
}

export default function* DashboardSagasWatch() {
  yield takeLatest(type.DASHBOARD_DATA_REQUEST, requestDashboardData);
  yield takeLatest(type.DASHBOARD_PLANS_REQUEST, requestDashboardPlansData);
  yield takeLatest(type.DASHBOARD_PACKAGES_REQUEST, requestDashboardPackagesData);
  yield takeLatest(type.EXPORT_CVS_REQUEST, exportCvsRequest);
  yield takeLatest(type.EXPORT_EXCEL_REQUEST, exportExcelRequest);
  yield takeLatest(type.REPORT_DATA_REQUEST, reportDataRequest);

}
