import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../../network/apis/dashboard/jobDescriptions";
import * as Actions from "../../actions/dashboard/jobDescription";
import * as type from "../../types/dashboard/jobDescription";
import { dispatchError, showSnackbar } from "../../../utils/Shared";
import History from "routes/History";
import store from "store";
import messages from "assets/Local/messages";
import { showSnackbarAction } from "store/actions/snackbar";

function* requestDashboardJobDescriptions({ payload }) {
  try {
    const response = yield call(API.jobDescriptionRequest, payload);
    yield put(Actions.dashboardJobDescriptionRecieve(response.data));
  } catch (error) {
    dispatchError(error.response.data);
  }
}

function* getDashboardJobDescriptions({ payload }) {
  try {
    const response = yield call(API.getjobDescriptionRequest, payload);
    yield put(Actions.getDashboardJobDescriptionRecieve(response.data));
  } catch (error) {
    console.log(error);
    dispatchError(error.response.data);
  }
}

function* setJobDescriptionsRequest({ payload }) {
  try {
    const requestStatus = History.location.pathname.includes("edit")
      ? API.updateJobDescriptionRequest
      : API.setjobDescriptionRequest;
    const response = yield call(requestStatus, payload);
    if (response) showSnackbar("done");
    History.push("/admin/dashboard/jobDescriptions");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

function* deleteJobDescriptionsRequest({ payload }) {
  try {
    const response = yield call(API.deleteJobDescriptionRequest, payload);
    if (response) showSnackbar("done");
    const result = yield call(API.jobDescriptionRequest, payload);
    yield put(Actions.dashboardJobDescriptionRecieve(result.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
export default function* DashboardJobDescriptionSagasWatch() {
  yield takeLatest(
    type.DASHBOARD_JOB_DESCRIPTIONS_REQUEST,
    requestDashboardJobDescriptions
  );
  yield takeLatest(
    type.SET_JOB_DESCRIPTIONS_REQUEST,
    setJobDescriptionsRequest
  );

  yield takeLatest(
    type.DELETE_JOB_DESCRIPTIONS_REQUEST,
    deleteJobDescriptionsRequest
  );

  yield takeLatest(
    type.GET_DASHBOARD_JOB_DESCRIPTIONS_REQUEST,
    getDashboardJobDescriptions
  );
}
