import * as types from "../../types/website/auth";

const INITIAL_STATE = {
    primary_color: '#808080',
    secondary_color :'#808080',
    defaultPrimaryColor: '#86364e',
    defaultSecondaryColor: '#ea8685',
    TransparentColor: 'rgba(172, 69, 86, 0.06)',
    singleBlog:null,
    blogs:null,
    blogsWebsite:null,
    latestBlogWebsite:null,
    singleBlogWebsite:null,
}

export default (state = INITIAL_STATE , action) => {
    switch (action.type) {
        case types.GET_COMPANY_PORTAL_SETUP_RECIEVE:
            return { ...state, ...action.payload };
        case types.GET_BLOG_SINGLE_RECIEVE:
             return { ...state,singleBlog: action.payload };
        case types.GET_BLOGS_LIST_RECIEVE:
             return { ...state, blogs:action.payload };
        case types.GET_BLOGS_LIST_WEBSITE_RECIEVE:
             return { ...state, blogsWebsite:action.payload };
        case types.GET_LATEST_BLOG_RECIEVE:
             return { ...state, latestBlogWebsite:action.payload };
             case types.GET_BLOG_SINGLE_WEBSITE_RECIEVE:
                return { ...state, singleBlogWebsite:action.payload };
        case types.CLEAR_BLOG_REQUEST:
        return { ...state, singleBlog:null };
        default: return state;
    }
}