import { axiosInstance } from "../config";

let handlerEnabled = true;




const emailTemplateTypesRequest = async () => {
  return await axiosInstance.get("/hrcomsite/email_templates/types", {
    headers: { domain: "hrcomadministration" },
    handlerEnabled,
  });
};
const emailTemplateDetailsRequest = async (type) => {
  return await axiosInstance.get(`/hrcomsite/email_templates/${type}`, {
    headers: { domain: "hrcomadministration" },
    handlerEnabled,
  });
};

const requestSendEmailTemplate = async (data) => {
  return await axiosInstance.put(
    `/hrcomsite/email_templates/${data.id}`,
    data,
    { handlerEnabled }
  );
}
export default {
  requestSendEmailTemplate,
  emailTemplateTypesRequest,
  emailTemplateDetailsRequest
};
