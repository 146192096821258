
export const EMAIL_TEMPLATE_TYPES_REQUEST="EMAIL_TEMPLATE_TYPES_REQUEST"
export const EMAIL_TEMPLATE_TYPES_RECIEVE="EMAIL_TEMPLATE_TYPES_RECIEVE"

export const EMAIL_TEMPLATE_DETAILS_REQUEST="EMAIL_TEMPLATE_DETAILS_REQUEST"
export const EMAIL_TEMPLATE_DETAILS_RECIEVE="EMAIL_TEMPLATE_DETAILS_RECIEVE"

export const SEND_EMAIL_TEMPLATE_REQUEST="SEND_EMAIL_TEMPLATE_REQUEST"

export const CLEAR_EMAIL_TEMPLATE_REQUEST="CLEAR_EMAIL_TEMPLATE_REQUEST"
export const CLEAR_EMAIL_TEMPLATE_RECIEVE="CLEAR_EMAIL_TEMPLATE_RECIEVE"
