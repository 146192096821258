import { axiosInstance } from "../config";

let handlerEnabled = true;

const jobDescriptionRequest = async ({ pageNumber ,name}) => {
  let params = {
    page_number: pageNumber ? pageNumber : 1,
    page_size: 10,
    name
  };
  return await axiosInstance.get("/hrcomsite/job_description", {
    params,
    handlerEnabled,
  });
};
const getjobDescriptionRequest = async (id) => {
  return await axiosInstance.get(`/hrcomsite/job_description/${id}`, {
    handlerEnabled,
  });
};

const setjobDescriptionRequest = async (data) => {
  return await axiosInstance.post(`/hrcomsite/job_description`,data, {
    handlerEnabled,
  });
};
const updateJobDescriptionRequest = async (data) => {
  return await axiosInstance.put(`/hrcomsite/job_description/${data.id}`,data, {
    handlerEnabled,
  });
};
const deleteJobDescriptionRequest = async (id) => {
  return await axiosInstance.delete(`/hrcomsite/job_description/${id}`, {
    handlerEnabled,
  });
};



export default {
  jobDescriptionRequest,
  getjobDescriptionRequest,
  setjobDescriptionRequest,
  updateJobDescriptionRequest,
  deleteJobDescriptionRequest,
};
