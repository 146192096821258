import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../../network/apis/dashboard/sendEmailNotifications";
import * as Actions from "../../actions/dashboard/sendEmailNotifications";
import * as type from "../../types/dashboard/sendEmailNotifications";
import { dispatchError, showSnackbar } from "../../../utils/Shared";
import History from "routes/History";
import store from "store";
import messages from "assets/Local/messages";
import { showSnackbarAction } from "store/actions/snackbar";

function* requestDashboardsendEmailNotifications({ payload }) {
  try {
    const response = yield call(API.emailNotificationRequest, payload);
    yield put(Actions.dashboardSendEmailNotificationsRecieve(response.data));
  } catch (error) {
    dispatchError(error.response.data);
  }
}

function* getDashboardMessages({ payload }) {
  try {
    const response = yield call(API.getMessagesRequest, payload);
    yield put(Actions.getDashboardMessageRecieve(response.data));
  } catch (error) {
    console.log(error);
    dispatchError(error.response.data);
  }
}

function* getDashboardMessagesDuplicate({ payload }) {
  try {
    const response = yield call(API.getMessagesDuplicateRequest, payload);
     yield put(Actions.setDuplicateEmailNotificatiosRecieve(response.data));
   
  } catch (error) {
    console.log(error);
    dispatchError(error.response.data);
  }
}

function* setSendEmailNotificationRequest({ payload }) {
  try {
    const requestStatus = History.location.pathname.includes("edit")
      ? API.updateMessagesRequest
      : API.setSendEmailNotificationRequest;
    const response = yield call(requestStatus, payload);
    if (response) showSnackbar("done");
    History.push("/admin/dashboard/sendEmailNotificationList");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

function* deleteSendEmailNotificationsRequest({ payload }) {
  try {
    const response = yield call(API.deleteSendEmailNotificationsRequest, payload);
    if (response) showSnackbar("NotificationHasbeenDeleted");
    const result = yield call(API.emailNotificationRequest, payload);
    yield put(Actions.dashboardSendEmailNotificationsRecieve(result.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export default function* DashboardsendEmailNotificationsSagasWatch() {
  yield takeLatest(
    type.DASHBOARD_SEND_EMAIL_NOTIFICATIONS_REQUEST,
    requestDashboardsendEmailNotifications
  );
  yield takeLatest(
    type.SET_SEND_EMAIL_NOTIFICATIONS_REQUEST,
    setSendEmailNotificationRequest
  );

  yield takeLatest(
    type.DELETE_SEND_EMAIL_NOTIFICATIONS_REQUEST,
    deleteSendEmailNotificationsRequest
  );
  yield takeLatest(
    type.DUPLICATE_EMAIL_NOTIFICATIONS_REQUEST,
    getDashboardMessagesDuplicate
  );

  yield takeLatest(
    type.GET_DASHBOARD_MESSAGES_REQUEST,
    getDashboardMessages
  );
}
