import * as types from "../../types/dashboard/sendEmailNotifications";

export const dashboardSendEmailNotificationsRequest = (data) => {
  return {
    type: types.DASHBOARD_SEND_EMAIL_NOTIFICATIONS_REQUEST,
    payload: data,
  };
};

export const dashboardSendEmailNotificationsRecieve = (data) => {
  return {
    type: types.DASHBOARD_SEND_EMAIL_NOTIFICATIONS_RECIEVE,
    payload: data,
  };
};


export const setSendEmailNotificatiosRequest = (data) => {
  return {
    type: types.SET_SEND_EMAIL_NOTIFICATIONS_REQUEST,
    payload: data,
  };
};
export const setDuplicateEmailNotificatiosRequest = (data) => {
  return {
    type: types.DUPLICATE_EMAIL_NOTIFICATIONS_REQUEST,
    payload: data,
  };
};
export const setDuplicateEmailNotificatiosRecieve = (data) => {
  return {
    type: types.DUPLICATE_EMAIL_NOTIFICATIONS_RECIEVE,
    payload: data,
  };
};

export const deleteSendEmailNotificationsRequest = (data) => {
  return {
    type: types.DELETE_SEND_EMAIL_NOTIFICATIONS_REQUEST,
    payload: data,
  };
};


export const getDashboardMessageRecieve = (data) => {
  return {
    type: types.GET_DASHBOARD_MESSAGES_RECIEVE,
    payload: data,
  };
};
export const getDashboardMessageRequest = (data) => {
  return {
    type: types.GET_DASHBOARD_MESSAGES_REQUEST,
    payload: data,
  };
};

export const clearDashboardMessageRequest = (data) => {
  return {
    type: types.CLEAR_DASHBOARD_MESSAGES_REQUEST,
    payload: data,
  };
};
export const clearDashboardMessageRecieve = (data) => {
  return {
    type: types.CLEAR_DASHBOARD_MESSAGES_RECIEVE,
    payload: data,
  };
};