import { call, put, takeEvery } from "redux-saga/effects";
import API from "../../../network/apis/website/apis";
import * as ACTION from "../../actions";
import * as ACTION_SUBSCRIBE from "../../actions/website/subscribe";
import * as ACTION_ABOUTUS from "../../actions/website/aboutUs";
import * as ACTION_CONTACTUS from "../../actions/website/contactUs";
import * as ACTION_REGISTER from "../../actions/website/registartion";
import * as ACTION_JOBSEEKER from "../../actions/website/jobSeeker";
import * as ACTION_Blog from "../../actions/website/auth";

import { dispatchError, showSnackbar } from "../../../utils/Shared";
import {
  companyPortalBaseURL,
  companyPortalRedirectionPages,
  getEnv
} from "utils/Redirections";
import { getURLParams } from "utils/Shared";
import * as types from "../../types";
import history from "../../../routes/History";
import store from "../../index";
import Cookies from 'js-cookie';
import * as typesBlog from "../../types/website/auth";
export function* getHeroContent() {
  try {
    const response = yield call(API.getHeroContentAPI);
    yield put(ACTION.receiveHeroContent(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* getInfoContent() {
  try {
    const response = yield call(API.getInfoContentAPI);
    yield put(ACTION.receiveInfoContent(response.data.data));
  } catch (err) {
    console.log(err);
  }
}
export function* getCounterContent() {
  try {
    const response = yield call(API.getCounterContentAPI);
    yield put(ACTION.receiveCounterContent(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* getLogosSection() {
  try {
    const response = yield call(API.getLogosSectionAPI);
    yield put(ACTION.receiveLogsContent(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* getProductsContent() {
  try {
    const response = yield call(API.getProductsContentAPI);
    yield put(ACTION.receiveProductsContent(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* getNewsContent() {
  try {
    const response = yield call(API.getNewsContentAPI);
    yield put(ACTION.receiveNewsContent(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* getFeaturesContent() {
  try {
    const response = yield call(API.getFeaturesContentAPI);
    yield put(ACTION.receiveFeaturesContent(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* getReviewsContent() {
  try {
    const response = yield call(API.getReviewsContentAPI);
    yield put(ACTION.receiveReviewsContent(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* getQuestionsContent() {
  try {
    const response = yield call(API.getQuestionsContentAPI);
    yield put(ACTION.receiveQuestionsContent(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* subscribe(action) {
  try {
    const response = yield call(API.postSubscribe, action.payload);
    yield put(ACTION_SUBSCRIBE.recieveSubscribe(response.data));
    yield put(ACTION.handleModalStateAction(true));
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* getAboutUsContent() {
  try {
    const response = yield call(API.getAboutUs);
    yield put(ACTION_ABOUTUS.receiveAboutUsContent(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* getProductDetails(action) {
  try {
    const response = yield call(API.getProductDetails, action.payload);
    yield put(ACTION.receiveProductDetailsContent(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* getNewsDetails(action) {
  try {
    const response = yield call(API.getNewsDetails, action.payload);
    yield put(ACTION.receiveNewsDetailsContent(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* contactUsForm(action) {
  try {
    const response = yield call(API.postContactUs, action.payload);
    yield put(ACTION_CONTACTUS.recieveContactUsForm(response.data));

    if (!history.location.pathname.includes("Contact-us")) {
      showSnackbar("contactModalSuccess");
    } else {
      yield put(ACTION.handleModalStateAction(true));
    }
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* getContactUsContent() {
  try {
    const response = yield call(API.getContactUs);
    yield put(ACTION_CONTACTUS.recieveContactUsContent(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* register(action) {
  try {
    const response = yield call(API.registration, action.payload);
    yield put(ACTION_REGISTER.receiveRegistration(response.data));
    showSnackbar("done");
    localStorage.setItem("userId", response.data.user_id);
    window.location.href = `${response.data.redirect_url}`;
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* sendEmailAgain(action) {
  try {
    const response = yield call(API.sendEmailAgain, action.payload);
    yield put(ACTION_REGISTER.receiveSendAgainEmail(response.data));
    if (response.status === 201)
      window.location.href = `${response.data.redirect_url}/login`;
    showSnackbar("done");
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* confirmEmail(action) {
  try {
    const response = yield call(API.confirmEmail, action.payload);
    yield put(ACTION_REGISTER.receiveConfirmEmails(response.data));
    showSnackbar("done");
    window.location.href = `${response.data.data.redirect_url}`;
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* confirmEmailWithoutPassword(action) {
  try {
    const response = yield call(API.confirmEmailWithoutPassword, action.payload);
    window.location.href = `${response.data.data.redirect_url}`;
  } catch (err) {
    dispatchError(err.response.data);
  }
}
export function* getCandidateProfileSetup() {
  try {
    const response = yield call(API.getCandidateProfileSetup);
    yield put(
      ACTION_JOBSEEKER.receiveGetCandidateProfileSetup(response.data.data)
    );
  } catch (err) {
    console.log(err);
  }
}

export function* getIsCandidateRegistredBefore({ payload }) {
  try {
    const response = yield call(API.isCandidateRegistredBefore, {
      personal_email: payload.personal_email,
    });
    yield put(
      ACTION_JOBSEEKER.receiveIsCandidateRegisteredBefore(response.data.data)
    );
    if (response.data.data == false)
      store.dispatch(ACTION_JOBSEEKER.requestAddJobSeeker(payload.mainData));
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* addJobSeeker({ payload }) {
  try {
    yield call(API.addJobSeeker, payload);
    showSnackbar("done");
    history.push("/");
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* requestNewDemo({ payload }) {
  try {
    yield call(API.requestNewDemo, payload);
    showSnackbar("done");
    history.push("/");
  } catch (err) {
    dispatchError(err.response.data);
  }
}
export function* addEditCompanyOffice365Integration({ payload }) {
  try {
    yield call(API.addEditCompanyOffice365Integration, payload);
    Cookies.remove("t-calendar", { domain: getEnv() })
    window.location.href = `${companyPortalBaseURL(getURLParams("state"))}/${
      companyPortalRedirectionPages.officeCalendar
    }`;
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* createNewBlogrequest({ payload }) {
  try {
    yield call(API.createNewBlog, payload);
    showSnackbar("done");
    history.push("/admin/dashboard/blogs");
  } catch (err) {
    dispatchError(err.response.data);
  }
}
export function* updateSingleBlog({ payload }) {
  try {
    yield call(API.updateSingleBlog, payload);
    showSnackbar("done");
    history.push("/admin/dashboard/blogs");
 
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* deleteSingleBlog({ payload }) {
  try {
    yield call(API.deleteSingleBlog, payload);
    showSnackbar("done");
 
  } catch (err) {
    dispatchError(err.response.data);
  }
}

function* getBlogsList({ payload }) {
  try {
    const response = yield call(API.listBlogs, payload);
    yield put(ACTION_Blog.getBlogsListRecieve(response.data));
  } catch (error) {
    console.log(error);
  }
}

function* getBlogsListWebsite({ payload }) {
  try {
    const response = yield call(API.listBlogsWebsite, payload);
    yield put(ACTION_Blog.getBlogsListWebsiteRecieve(response.data));
  } catch (error) {
    console.log(error);
  }
}

function* getSingleBlog({ payload }) {
  try {
    const response = yield call(API.getSingleBlog, payload);
    yield put(ACTION_Blog.getSingleBlogRecieve(response.data.data));
  } catch (error) {
    console.log(error);
  }
}





function* getSingleWebsiteBlog({ payload }) {
  try {
    const response = yield call(API.getSingleBlogWebsite, payload);
    yield put(ACTION_Blog.getSingleBlogWebsiteRecieve(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* getLatestBlog({ payload }) {
  try {
    const response = yield call(API.latestBlogWebsite, payload);
    yield put(ACTION_Blog.getLatestBlogRecieve(response.data.data));
  } catch (error) {
    console.log(error);
  }
}
export function* websiteGeneralSagasWatch() {
  yield takeEvery(types.FETCH_HERO_REQUEST, getHeroContent);
  yield takeEvery(types.FETCH_INFO_REQUEST, getInfoContent);
  yield takeEvery(types.FETCH_COUNTER_REQUEST, getCounterContent);
  yield takeEvery(types.FETCH_LOGOS_REQUEST, getLogosSection);
  yield takeEvery(types.FETCH_PRODUCTS_REQUEST, getProductsContent);
  yield takeEvery(types.FETCH_NEWS_REQUEST, getNewsContent);
  yield takeEvery(types.FETCH_FEATURES_REQUEST, getFeaturesContent);
  yield takeEvery(types.FETCH_REVIEWS_REQUEST, getReviewsContent);
  yield takeEvery(types.FETCH_QUESTIONS_REQUEST, getQuestionsContent);
  yield takeEvery(types.FETCH_SUBSCRIBE_REQUEST, subscribe);
  yield takeEvery(types.FETCH_ABOUTUS_REQUEST, getAboutUsContent);
  yield takeEvery(types.FETCH_PRODUCT_DETAILS_REQUEST, getProductDetails);
  yield takeEvery(types.FETCH_NEWS_DETAILS_REQUEST, getNewsDetails);
  yield takeEvery(types.FETCH_CONTACT_US_FORM_REQUEST, contactUsForm);
  yield takeEvery(types.FETCH_CONTACT_US_REQUEST, getContactUsContent);
  yield takeEvery(types.REGISTRATION_REQUEST, register);
  yield takeEvery(types.SEND_AGAIN_EMAIL_REQUEST, sendEmailAgain);
  yield takeEvery(types.CONFIRM_EMAIL_REQUEST, confirmEmail);
  yield takeEvery(typesBlog.CREATE_NEW_BLOG_REQUEST, createNewBlogrequest);
  yield takeEvery(typesBlog.UPDATE_BLOG_REQUEST, updateSingleBlog);
  yield takeEvery(typesBlog.DELETE_BLOG_REQUEST, deleteSingleBlog);
  yield takeEvery(typesBlog.GET_BLOGS_LIST_REQUEST, getBlogsList);
  yield takeEvery(typesBlog.GET_BLOGS_LIST_WEBSITE_REQUEST, getBlogsListWebsite);

  yield takeEvery(typesBlog.GET_BLOG_SINGLE_WEBSITE_REQUEST, getSingleWebsiteBlog);


  yield takeEvery(typesBlog.GET_BLOG_SINGLE_REQUEST, getSingleBlog);

  yield takeEvery(typesBlog.GET_LATEST_BLOG_REQUEST, getLatestBlog);



  yield takeEvery(types.CONFIRM_EMAIL_WITHOUT_PASSWORD_REQUEST, confirmEmailWithoutPassword);
  yield takeEvery(
    types.REQUEST_IS_CANDIDATE_REGISTRED_BEFORE,
    getIsCandidateRegistredBefore
  );
  yield takeEvery(types.REQUEST_GET_CANDIDATE_SETUP, getCandidateProfileSetup);
  yield takeEvery(types.REQUEST_ADD_JOB_SEEKER, addJobSeeker);
  yield takeEvery(types.REQUEST_NEW_DEMO, requestNewDemo);
  yield takeEvery(
    types.ADD_EDIT_OFFICE365_INTEGRATION_REQUEST,
    addEditCompanyOffice365Integration
  );
}
