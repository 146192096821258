import History from "routes/History";
import store from "../store";
import messages from "../assets/Local/messages";
import { showSnackbarAction } from "../store/actions/snackbar";
import { shallowEqual } from "recompose";
const queryString = require("query-string");
export const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

export function pushToUrlNewParam(page) {
  History.push({
    search: `?page=${page}`,
  });
}
export function prepareParams(params) {
  let paramString = "";
  Object.keys(params).forEach((item, index) => {
    const value = `${item}=${params[item]}`;
    const paramsChar = index < Object.keys(params).length - 1 ? "&" : "";
    paramString = paramString.concat("", value + paramsChar);
  });
}
export const shallowEqualValues = (init, values) => {
  return shallowEqual(init, values);
};

export function excludeEmptyValues(values) {
  let result = {};
  Object.keys(values).forEach((item) => {
    if (values[item] !== "" && values[item] != null) {
      result = {
        ...result,
        [item]: values[item],
      };
    }
  });
  return result;
}
export function dispatchError(data) {
  if (data) {
    const errorMsg = data.error.message;
    store.dispatch(showSnackbarAction(errorMsg, "error"));
  }
}

export function getURLParams(paramName) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(paramName);
}

export function showSnackbar(message, custom_message) {
  const lang = store.getState().locale.lang;
  store.dispatch(
    showSnackbarAction(
      custom_message ? message : messages[lang].snackbar[message],
      "success"
    )
  );
}

export const getYesterdayDate = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
};

export const static_features_plans = {
  basic: {
    customize_pipeline_stage: true,
    manage_roles_and_permissions: true,
    e_signatures: true,
    pre_screening_video_assessment: true,
    pre_screening_text_assessment: true,
    live_video_interview: true,
    brand_your_post: true,
    Dedicated_account_manager: false,
    google_search: true,
    send_sms_to_candidates: true,
    single_sign_on: true,
  },
  advance: {
    customize_pipeline_stage: true,
    manage_roles_and_permissions: true,
    e_signatures: true,
    pre_screening_video_assessment: true,
    pre_screening_text_assessment: true,
    live_video_interview: true,
    brand_your_post: true,
    Dedicated_account_manager: false,
    google_search: true,
    send_sms_to_candidates: true,
    single_sign_on: true,
  },
  premium: {
    customize_pipeline_stage: true,
    manage_roles_and_permissions: true,
    e_signatures: true,
    pre_screening_video_assessment: true,
    pre_screening_text_assessment: true,
    live_video_interview: true,
    brand_your_post: true,
    Dedicated_account_manager: true,
    google_search: true,
    send_sms_to_candidates: true,
    single_sign_on: true,
  },
};
export const validPhoneNumber = (number, code) => {
  try {
    return !phoneUtil.isValidNumberForRegion(
      phoneUtil.parse(number, code),
      code
    );
  } catch (error) {
    return true;
  }
};
export const validPhoneNumberOptional = (number, code) => {
  if (number.length > 0) {
    try {
      return !phoneUtil.isValidNumberForRegion(
        phoneUtil.parse(number, code),
        code
      );
    } catch (error) {
      return true;
    }
  }
};

export const updateURLocalization = (l) => {
  const locale = getURLParams("locale");
  if (locale) {
    History.push({
      pathname: History.location.pathname,
      search: `?locale=${l}`,
    });
  }
};

export const blobToBase64 = async (blob, fn) => {
  return new Promise((resolve, onerror) => {
    //represent the  blob image as a file,
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = function (event) {
      resolve(event.target.result);
    };
  });
};

export const removeParam = (param) => {
  const params = queryString.parse(window.location.search);
  delete params[param];
  History.push({
    pathname: History.location.pathname,
    search: queryString.stringify(params),
  });
};

export function extractDomain(url) {
  let domain;
  //find & remove protocol (http, ftp, etc.) and get domain
  if (url.indexOf("://") > -1) {
    domain = url.split('/')[2];
  }
  else {
    domain = url.split('/')[0];
  }
  //find & remove www
  if (domain.indexOf("www.") > -1) {
    domain = domain.split('www.')[1];
  }

  domain = domain.split(':')[0]; //find & remove port number
  domain = domain.split('?')[0]; //find & remove url params
  let modifiedDomain = domain.split(".")[0]

  return modifiedDomain;
}
